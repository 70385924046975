import { useContext, useEffect, useRef, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
// react-router-dom components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import client from "ApiClient";
import { Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import SimpleReactValidator from "simple-react-validator";
import Moment from "moment-timezone";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import Triggers from "components/triggers";
import moment from "moment/moment";
import { FeatureFlags } from "context/FeatureFlags";
import PieChart from "examples/Charts/PieChart";
import GetSegmentation from "components/Segments/getSegmentation";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

/* eslint-disable */
function Details(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [pauseSeries, setPauseSeries] = useState(false);
  const [showTRacking, setShowTracking] = useState(false);
  const [isTracking, setIsTracking] = useState(false);
  const [subject, setSubject] = useState([]);
  const [broId, setBroId] = useState(props.broId);
  const [emcId, setEmcId] = useState("");
  const [name, setName] = useState("");
  const [envelopFrom, setEnvelopFrom] = useState("");
  const [displayFrom, setDisplayFrom] = useState("");
  const [testEmail, setTestEmail] = useState("");
  const [valueDate, setValueDate] = useState(Moment());
  const [dateFrom, setDateFrom] = useState(12);
  const [dateTo, setDateTo] = useState(12);
  const [dateAt, setDateAt] = useState();
  const [templateBody, setTemplateBody] = useState(props.body);
  const [htmlPreview, setHtmlPreview] = useState("");
  const [subjectPreview, setSubjectPreview] = useState("");
  const [sendMode, setSendMode] = useState("0");
  const [arrSenderProfiles, setArrSenderProfiles] = useState([]);
  const [senderProfile, setSenderProfile] = useState(-1);
  const [suppressions, setSuppressions] = useState([]);
  const [suppressionsSelected, setSuppressionsSelected] = useState([]);
  const [subjectPerformance, setSubjectPerformance] = useState(null);
  const [contentText, setContentText] = useState("");
  const [mimeType, setMimeType] = useState(0);
  const { openModal, closeModal } = props;
  const [, forceUpdate] = useState();
  const [jsonDesign, setJsonDesign] = useState();
  const [token, setToken] = useState(JSON.parse(localStorage.getItem("AuthorizationToken")));
  const [catalogs, setCatalogs] = useState([]);
  const [recipients, setRecipients] = useState(0);
  const [triggers, setTriggers] = useState(props.triggers);
  const [segmentsRows, setSegmentsRows] = useState(props.segments);
  const [errorContent, setErrorContent] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [openErrorSB, setOpenErrorSB] = useState(false);
  const closeErrorSB = () => setOpenErrorSB(false);
  const [openTestSB, setOpenTestSB] = useState(false);
  const closeTestSB = () => setOpenTestSB(false);
  const [broadcast, setBroadcast] = useState(null);
  const { features } = useContext(FeatureFlags);

  const simpleValidator = useRef(
    new SimpleReactValidator({
      validators: {
        sender: {
          // name the rule
          message: "Please select a sender profile",
          rule: (val, params, validator) => {
            return val >= 0;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true, // optional
        },
      },
    })
  );

  const emailEditorRef = useRef(null);

  const classes = useStyles();
  const theme = useTheme();

  document.addEventListener("focusin", (e) => {
    if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
      e.stopImmediatePropagation();
    }
  });

  const clearVariables = (reason) => {
    if (reason === "backdropClick") return;
    setName("");
    setSubject([]);
    setPauseSeries(false);
    setEnvelopFrom("");
    setDisplayFrom("");
    setValueDate(Moment());
    setContentText("");
    setMimeType(0);
    setTemplateBody("");
    setHtmlPreview("");
    setSubjectPreview("");
    setJsonDesign("");
    setSenderProfile(-1);
    setSuppressionsSelected([]);
    setBroadcast(null);
    setSegmentsRows(null);
    // setArticles("");
    // setAds("");
    props.closeModal();
  };

  const options = {
    method: "POST",
    url: `emailCopy/Preview`,
    headers: {
      "content-type": "application/json",
    },
  };

  const getBroadcast = async (unlayer) => {
    options.method = "GET";
    options.url = `broadcasts/GetById?bro_id=${props.broId}`;

    await client
      .request(options)
      .then((response) => {
        setBroadcast(response);
        setBroId(response.bro_id);
        setEmcId(response.bro_emc_id);
        setName(response.name);
        setSubject(response.subjects);
        setPauseSeries(response.pauseStandard);
        setEnvelopFrom(JSON.parse(response.bro_senderProfile).sep_envelopFrom);
        setDisplayFrom(JSON.parse(response.bro_senderProfile).sep_displayFrom);
        setValueDate(response.date);
        setContentText(response.html);
        setTemplateBody(response.html);
        setSegmentsRows(response.bro_segments);

        setIsTracking(response.bro_trackExternalLinkClicks);
        const jsonMode = response.bro_sendMode;
        setSendMode(jsonMode.mode.toString());
        switch (jsonMode.mode) {
          case 2:
            setDateAt(Moment(jsonMode.hourSpecific + ":" + jsonMode.minuteSpecific, "H:m"));
            break;
        }
        const options = {
          method: "POST",
          url: `emailCopy/Preview`,
          headers: {
            "content-type": "application/json",
          },
        };

        options.data = {
          subject: response.subjects[0],
          body: response.html,
        };

        client
          .request(options)
          .then((response) => {
            setHtmlPreview(response.body);
          })
          .catch((error) => {
            console.log(error);
          });

        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSenderProfiles = async () => {
    options.method = "GET";
    options.url = `senderProfiles`;
    await client
      .request(options)
      .then((response) => {
        setArrSenderProfiles(response);
        if (response.length > 0 && broadcast != null)
          setSenderProfile(
            response.filter(
              (item) => item.sep_id === JSON.parse(broadcast.bro_senderProfile).sep_id
            )[0].sep_id
          );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSuppressions = async () => {
    const options = {
      method: "GET",
      url: `suppression`,
      headers: {
        "content-type": "application/json",
      },
    };

    await client
      .request(options)
      .then((response) => {
        setSuppressions(response);
        if (response.length > 0 && broadcast != null)
          setSuppressionsSelected(
            response
              .filter((s) => broadcast.bro_spr_ids.split(",").includes(s.spr_id.toString()))
              .map((a) => a.spr_name)
          );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCatalogs = async () => {
    const options = {
      method: "GET",
      url: `catalogs`,
      headers: {
        "content-type": "application/json",
      },
    };

    await client
      .request(options)
      .then((response) => {
        let catalog = [];
        response
          .filter((cat) => cat.cat_status == 0)
          .map((item) => {
            catalog.push({ label: item.cat_name, value: item.cat_id });
          });
        setCatalogs(catalog);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (senderProfile >= 0) {
      options.method = "GET";
      options.url = `senderProfiles/tracking?sep_id=${senderProfile}`;
      client
        .request(options)
        .then((response) => {
          setShowTracking(response);
        })
        .catch((error) => {
          console.log(error);
        });
    } else setShowTracking(false);
  }, [senderProfile]);

  useEffect(() => {
    setIsLoading(true);
    getCatalogs();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getSuppressions();
    getSenderProfiles();
  }, [broadcast]);

  useEffect(() => {
    if (emcId != "") {
      options.method = "GET";
      options.url = `emailCopy/subjectStats?emc_id=${emcId}`;
      client
        .request(options)
        .then((response) => {
          setSubjectPerformance(response);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
  }, [emcId]);

  const onReady = (unlayer) => {
    unlayer.setBodyValues({
      contentWidth: "900px", // or percent "50%"
    });
    unlayer.registerCallback("previewHtml", async function (params, done) {
      const options = {
        method: "POST",
        url: `emailCopy/Preview`,
        headers: {
          "content-type": "application/json",
        },
      };

      options.data = {
        subject: subject,
        body: params.html,
      };

      client
        .request(options)
        .then((response) => {
          setSubjectPreview(response.subject);
          done({
            html: response.body,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    });
    unlayer.setMergeTags({
      title: {
        name: "Title",
        value: "[title]",
        sample: "[title]",
      },
      currentDate: { name: "Current date", value: "[current-date]", sample: "[current-date]" },
      broadcastId: { name: "Broadcast ID", value: "[broadcast-id]", sample: "[broadcast-id]" },
      email: { name: "Email", value: "[email]", sample: "[email]" },
      phone: { name: "Phone", value: "[phone]", sample: "[phone]" },
      firstName: { name: "First Name", value: "[first]", sample: "[first]" },
      lastName: { name: "Last Name", value: "[last]", sample: "[last]" },
      city: { name: "City", value: "[city]", sample: "[city]" },
      state: { name: "State", value: "[state]", sample: "[state]" },
      country: { name: "Country", value: "[country]", sample: "[country]" },
      optinDate: { name: "Optin Date", value: "[optin-date]", sample: "[optin-date]" },
      contactUsUrl: {
        name: "Contact Us URL",
        value: "[contact-us-url]",
        sample: "[contact-us-url]",
      },
      contactUsName: {
        name: "Contact Us Name",
        value: "[contact-us-name]",
        sample: "[contact-us-name]",
      },
      companyName: { name: "Company Name", value: "[company-name]", sample: "[company-name]" },
      companyAddress: {
        name: "Company Address",
        value: "[company-address]",
        sample: "[company-address]",
      },
      companyCountry: {
        name: "Company Country",
        value: "[company-country]",
        sample: "[company-country]",
      },
      unsubscribeUrl: {
        name: "Unsubscribe URL",
        value: "[unsubscribe-url]",
        sample: "[unsubscribe-url]",
      },
      unsubscribeParagraph: {
        name: "Unsubscribe Paragraph",
        value: "[unsubscribe-paragraph]",
        sample: "[unsubscribe-paragraph]",
      },
    });
  };

  useEffect(() => {
    if (props.broId) getBroadcast();
  }, [props.broId]);

  useEffect(() => {
    if (segmentsRows != null) {
      // console.log("calculateRecipients");
      options.method = "POST";
      options.url = `broadcasts/calculateRecipients`;
      options.data = segmentsRows;

      client
        .request(options)
        .then((response) => {
          setRecipients(response);
        })
        .catch((error) => {
          console.log(error);
        });
      // console.log(segmentsRows);
    }
  }, [segmentsRows]);

  const sendTest = () => {
    if (simpleValidator.current.allValid()) {
      setIsLoading(true);
      const options = {
        method: "POST",
        url: `broadcasts/Send`,
        headers: {
          "content-type": "application/json",
        },
      };
      const unlayer = emailEditorRef.current?.editor;

      unlayer?.exportHtml((data) => {
        const html = data.html;
        const design = data.design;

        options.data = JSON.stringify({
          bro_id: 0,
          bro_emc_id: 0,
          name: "",
          displayfrom: displayFrom,
          fromaddress: envelopFrom,
          subjects: subject,
          html: html,
          pauseStandard: false,
          date: Moment(),
          bro_segments: null,
          recipient: testEmail,
          trigger_events: null,
          bro_senderProfile: JSON.stringify(
            arrSenderProfiles.filter((item) => item.sep_id == senderProfile)[0]
          ),
          bro_tracking: isTracking,
        });

        client
          .request(options)
          .then((response) => {
            setOpenTestSB(true);
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleSendTest = (event) => {
    setTestEmail(event.target.value);
  };

  const getSendingTime = () => {
    switch (sendMode) {
      case "0":
        return "Send when people engage";
      case "2":
        return "Send everything at " + moment(dateAt).format("hh:mm a");
      case "3":
        return "Send immediately";
    }
  };

  return (
    <Dialog
      open={openModal}
      onClose={(e, reason) => clearVariables(reason)}
      fullScreen
      disableEscapeKeyDown
      style={{ marginLeft: 300 }}
      sx={{
        //You can copy the code below in your theme
        background: "#F4F0F700",
        "& .MuiPaper-root": {
          background: theme.palette.background.default,
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent", // Try to remove this to see the result
        },
      }}
    >
      <MDBox
        variant="gradient"
        bgColor="primary"
        borderRadius="xs"
        coloredShadow="primary"
        mx={2}
        mt={-1}
        p={2}
        textAlign="center"
      >
        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          Details for broadcast {name}
        </MDTypography>
      </MDBox>
      <DialogContent>
        <MDBox px={2}>
          {broadcast && (
            <Grid container spacing={2} style={{ height: "100%" }}>
              <Grid item sm={6} xs={12}>
                <Card
                  style={{
                    height: "100%",
                    padding: 10,
                    backgroundColor: "#FFFFFF",
                    marginBottom: 10,
                  }}
                >
                  <MDBox mb={2}>
                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <MDTypography variant="body" color="text">
                          {moment(valueDate).format("MMMM Do YYYY")}
                        </MDTypography>
                      }
                      label={
                        <MDTypography variant="h6" fontWeight="medium">
                          Sending date:
                        </MDTypography>
                      }
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <MDTypography variant="body" color="text">
                          {getSendingTime()}
                        </MDTypography>
                      }
                      label={
                        <MDTypography variant="h6" fontWeight="medium">
                          Sending time:
                        </MDTypography>
                      }
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <MDTypography variant="body" color="text">
                          {name}
                        </MDTypography>
                      }
                      label={
                        <MDTypography variant="h6" fontWeight="medium">
                          Broadcast name:
                        </MDTypography>
                      }
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <MDTypography variant="body" color="text">
                          {subject.toString()}
                        </MDTypography>
                      }
                      label={
                        <MDTypography variant="h6" fontWeight="medium">
                          Subject(s):
                        </MDTypography>
                      }
                    />
                  </MDBox>
                  {arrSenderProfiles.length > 0 && senderProfile >= 0 && (
                    <MDBox mb={2}>
                      <FormControlLabel
                        labelPlacement="start"
                        control={
                          <MDTypography variant="body" color="text">
                            {
                              arrSenderProfiles.filter((sen) => sen.sep_id == senderProfile)[0]
                                .sep_envelopFrom
                            }
                          </MDTypography>
                        }
                        label={
                          <MDTypography variant="h6" fontWeight="medium">
                            Sender profile:
                          </MDTypography>
                        }
                      />
                    </MDBox>
                  )}
                  <MDBox mb={2} ml={2}>
                    <FormControlLabel
                      checked={isTracking}
                      onChange={() => setIsTracking(!isTracking)}
                      disabled
                      control={<Checkbox />}
                      label="Track opens and clicks"
                    />
                  </MDBox>
                </Card>
              </Grid>
              {subjectPerformance && subjectPerformance.datasets.data.length > 0 && (
                <Grid item sm={6} xs={12}>
                  <PieChart
                    title="Subject performance"
                    chart={subjectPerformance}
                    description="Subject lines performance"
                  />
                </Grid>
              )}
              {broadcast.bro_notes && (
                <Grid item xs={12}>
                  <Card
                    style={{
                      height: "100%",
                      padding: 10,
                      backgroundColor: "#FFFFFF",
                      marginBottom: 10,
                    }}
                  >
                    <MDBox mb={2} ml={2}>
                      <MDTypography variant="h5" fontWeight="medium">
                        Notes:
                      </MDTypography>
                      <MDTypography variant="h5" fontWeight="medium">
                        {broadcast.bro_notes}
                      </MDTypography>
                    </MDBox>
                  </Card>
                </Grid>
              )}
              <Grid item xs={12}>
                <Card style={{ padding: 10, backgroundColor: "#FFFFFF", marginBottom: 10 }}>
                  {suppressionsSelected.length > 0 && (
                    <MDBox mb={2} display="flex" justifyContent="left" textAlign="center">
                      <FormControlLabel
                        labelPlacement="start"
                        control={
                          <MDTypography variant="h6" color="text">
                            {suppressionsSelected}
                          </MDTypography>
                        }
                        label={
                          <MDTypography variant="h6" fontWeight="medium">
                            Suppression lists:
                          </MDTypography>
                        }
                      />
                    </MDBox>
                  )}
                  <MDBox mb={2} ml={2}>
                    <MDTypography variant="h5" fontWeight="medium">
                      Segmentation:
                    </MDTypography>
                    <GetSegmentation segment={segmentsRows} />
                  </MDBox>
                  {triggers && triggers.length > 0 && (
                    <MDBox>
                      <MDTypography variant="h5" fontWeight="medium" ml={2}>
                        Triggers:
                      </MDTypography>
                      <Triggers
                        changeTriggers={(value) => onChangeTriggers(value)}
                        filterSegments={props.segments}
                        triggers={props.triggers}
                        series={props.series}
                        articles={props.articles}
                        ads={props.ads}
                        addButton={false}
                      />
                    </MDBox>
                  )}
                </Card>
              </Grid>
            </Grid>
          )}
          {(mimeType == 0 || mimeType == 2) && (
            <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
              <Grid container spacing={2}>
                <Grid item mb={2} xs={10}>
                  <MDTypography variant="h5" fontWeight="medium" mt={1}>
                    HTML content
                  </MDTypography>
                </Grid>

                <Grid item xs={12}>
                  <iframe
                    srcDoc={htmlPreview}
                    height="600"
                    width="100%"
                    allowFullScreen
                    style={{ border: "none" }}
                  />
                </Grid>
              </Grid>
            </Card>
          )}

          <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
            <Grid>
              <MDInput
                onChange={(event) => handleSendTest(event)}
                label="e-mails to test"
                style={{ marginBottom: 10, width: "100%" }}
              />
              <MDButton variant="gradient" color="success" onClick={sendTest} disabled={isLoading}>
                Send
              </MDButton>
            </Grid>
          </Card>
        </MDBox>
      </DialogContent>
      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDButton onClick={clearVariables} variant="outlined" color="secondary">
          Cancel
        </MDButton>
      </DialogActions>

      <MDSnackbar
        color="success"
        icon="success"
        title="Success"
        content="Your test email has been sent successfully"
        dateTime={""}
        open={openTestSB}
        onClose={closeTestSB}
        close={closeTestSB}
        bgWhite
      />
      <MDSnackbar
        color="error"
        icon="warning"
        title={errorTitle}
        content={errorContent}
        dateTime={""}
        open={openErrorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
      />
    </Dialog>
  );
}
export default Details;
