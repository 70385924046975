import spinner from "../../../assets/spinner.gif";
import client from "ApiClient";

export const ExpandText = (editor, opts = {}) => {
  const keyCustomCode = "expand-text-plugin__code";
  const typeCustomCode = "expand-text";
  const commandNameCustomCode = "expand-text:open-modal";
  let timedInterval = null;
  let modal = null;
  let prompt = null;

  const options = {
    isLoading: false,
    blockCustomCode: {},
    propsCustomCode: {},
    toolbarBtnCustomCode: {},
    placeholderScript: `<div style="pointer-events: none; padding: 10px;">
      <svg viewBox="0 0 24 24" style="height: 30px; vertical-align: middle;">
        <path d="M13 14h-2v-4h2m0 8h-2v-2h2M1 21h22L12 2 1 21z"></path>
        </svg>
      Custom code with <i>&lt;script&gt;</i> can't be rendered on the canvas
    </div>`,
    codeViewOptions: {},
    buttonLabel: "Expand text",
    commandCustomCode: {},
    ...opts,
  };

  editor.DomComponents.addType("script", {
    view: {
      onRender() {
        // @ts-ignore
        const { model, el } = this;
        const isCC = model.closestType(typeCustomCode);
        isCC && (el.innerHTML = "");
      },
    },
  });

  editor.DomComponents.addType(typeCustomCode, {
    model: {
      defaults: {
        name: "Expand Text",
        editable: true,
        ...options.propsCustomCode,
      },

      /**
       * Initilize the component
       */
      init() {
        // @ts-ignore
        this.on(`change:${keyCustomCode}`, this.onCustomCodeChange);
        const initialCode = this.get(keyCustomCode);
        !this.components().length && this.components(initialCode);
        const toolbar = this.get("toolbar");
        const id = "custom-code";

        // Add the custom code toolbar button if requested and it's not already in
        // @ts-ignore
        if (options && !toolbar.filter((tlb) => tlb.id === id).length) {
          // @ts-ignore
          toolbar.unshift({
            id,
            command: commandNameCustomCode,
            label: `<svg viewBox="0 0 24 24">
              <path d="M14.6 16.6l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4m-5.2 0L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4z"></path>
            </svg>`,
            ...options,
          });
        }
      },

      /**
       * Callback to launch on keyCustomCode change
       */
      // @ts-ignore
      onCustomCodeChange() {
        // @ts-ignore
        this.components(this.get(keyCustomCode));
      },
    },

    view: {
      events: {
        dblclick: "onActive",
      },

      init() {
        // @ts-ignore
        this.listenTo(this.model.components(), "add remove reset", this.onComponentsChange);
        // @ts-ignore
        this.onComponentsChange();
      },

      /**
       * Things to do once inner components of custom code are changed
       */
      // @ts-ignore
      onComponentsChange() {
        timedInterval && clearInterval(timedInterval);
        timedInterval = setTimeout(() => {
          // @ts-ignore
          const { model, el } = this;
          const content = model.get(keyCustomCode) || "";
          let droppable = true;

          // Avoid rendering codes with scripts
          // if (content.indexOf('<script') >= 0 && opts.placeholderScript) {
          //   el.innerHTML = opts.placeholderScript;
          //   droppable = false;
          // }

          model.set({ droppable });
        }, 0);
      },

      onActive() {
        // @ts-ignore
        const { model, em } = this;
        em.get("Commands").run(commandNameCustomCode, { target: model });
      },
    },
  });

  //LOAD BLOCKS
  editor.BlockManager.add(typeCustomCode, {
    label: "Smart Text",
    media: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M234.7 42.7L197 56.8c-3 1.1-5 4-5 7.2s2 6.1 5 7.2l37.7 14.1L248.8 123c1.1 3 4 5 7.2 5s6.1-2 7.2-5l14.1-37.7L315 71.2c3-1.1 5-4 5-7.2s-2-6.1-5-7.2L277.3 42.7 263.2 5c-1.1-3-4-5-7.2-5s-6.1 2-7.2 5L234.7 42.7zM46.1 395.4c-18.7 18.7-18.7 49.1 0 67.9l34.6 34.6c18.7 18.7 49.1 18.7 67.9 0L529.9 116.5c18.7-18.7 18.7-49.1 0-67.9L495.3 14.1c-18.7-18.7-49.1-18.7-67.9 0L46.1 395.4zM484.6 82.6l-105 105-23.3-23.3 105-105 23.3 23.3zM7.5 117.2C3 118.9 0 123.2 0 128s3 9.1 7.5 10.8L64 160l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L128 160l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L128 96 106.8 39.5C105.1 35 100.8 32 96 32s-9.1 3-10.8 7.5L64 96 7.5 117.2zm352 256c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L416 416l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L480 416l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L480 352l-21.2-56.5c-1.7-4.5-6-7.5-10.8-7.5s-9.1 3-10.8 7.5L416 352l-56.5 21.2z"/></svg>
    `,
    category: process.env.REACT_APP_SITE_TITLE + " AI",
    activate: true,
    select: true,
    content: { type: typeCustomCode },
    ...options,
  });

  //COMMANDS

  const appendToContent = (target, content) => {
    if (content instanceof HTMLElement) {
      target.appendChild(content);
    } else if (content) {
      target.insertAdjacentHTML("beforeend", content);
    }
  };

  // Add the custom code command
  editor.Commands.add(commandNameCustomCode, {
    keyCustomCode,

    run(editor, s, opts = {}) {
      const target = options.target || editor.getSelected();
      // @ts-ignore
      this.target = target;

      if (target?.get("editable")) {
        // @ts-ignore
        this.showCustomCode(target, opts);
      }
    },

    stop(editor) {
      editor.Modal.close();
    },

    /**
     * Method which tells how to show the custom code
     * @param  {Component} target
     */
    showCustomCode(target, options) {
      const title = "Use AI to enhance the quality of your writing.";
      const code = target.get(keyCustomCode) || "";
      // @ts-ignore
      const content = this.getContent();
      //console.log(content);
      const cr = code.replace(`<span id="expand-text-plugin__code">`, "").replace("</span>", "");

      // console.log(cr);
      if (cr) prompt = cr && cr.match(/data="([^"]*)"/)[1];

      modal = editor.Modal.open({ title, content });
      modal.onceClose(() => editor.stopCommand(commandNameCustomCode));
      // @ts-ignore
      // this.getCodeViewer().setContent(code);
      // console.log(prompt);
      document.getElementById("txtPrompt").value = prompt;
    },

    /**
     * Custom pre-content. Can be a simple string or an HTMLElement
     */
    getPreContent() {
      return `
  <div id="iqopk">
    <p>Write your text
    </p>
  </div>
  <textarea class="textarea" id="ie3a"></textarea>
  `;
    },

    /**
     * Custom post-content. Can be a simple string or an HTMLElement
     */
    getPostContent() {
      return "<div>Post content</div>";
    },

    /**
     * Get all the content for the custom code
     * @return {HTMLElement}
     */

    getContent() {
      // @ts-ignore
      //const codeViewer = this.getCodeViewer();
      const content = document.createElement("div");
      const pfx = editor.getConfig("stylePrefix");
      content.className = `${pfx}custom-code`;
      // @ts-ignore
      //appendToContent(content, this.getPreContent());
      //console.log("CODEVIEWER", codeViewer.getElement());
      content.appendChild(this.getContentText());
      // content.appendChild(document.createElement("textarea"));
      // @ts-ignore
      //appendToContent(content, this.getPostContent());
      // @ts-ignore
      appendToContent(content, this.getContentActions());
      // codeViewer.refresh();
      // setTimeout(() => codeViewer.focus(), 0);

      return content;
    },

    getContentText() {
      const content = document.createElement("div");

      var closeSpan = document.createElement("span");
      closeSpan.textContent = "Write your text";
      content.appendChild(closeSpan);

      const txt = document.createElement("textarea");
      txt.id = "txtPrompt";
      txt.placeholder = "Type your text here so our AI can assist you";
      txt.style.padding = "5px";
      txt.style.width = "100%";
      txt.style.height = "200px";
      txt.style.color = "#000";
      content.appendChild(txt);

      return content;
    },

    /**
     * Get the actions content. Can be a simple string or an HTMLElement
     * @return {HTMLElement|String}
     */
    getContentActions() {
      const cont = document.createElement("div");

      const pfx = editor.getConfig("stylePrefix");

      const btne = document.createElement("button");
      btne.id = "expand";
      btne.setAttribute("type", "button");
      btne.innerHTML = "Expand text";
      btne.className = `button`;
      btne.style.marginRight = "10px";
      btne.style.backgroundColor = "#0d63cb";
      btne.style.color = "white";
      btne.style.padding = "5px";
      btne.style.borderRadius = "5px";
      btne.style.borderColor = "#0d63cb";
      // @ts-ignore
      btne.onclick = () => this.handleSave("expanded");
      cont.appendChild(btne);

      const btns = document.createElement("button");
      btns.id = "summarize";
      btns.setAttribute("type", "button");
      btns.innerHTML = "Summarize text";
      btns.className = `button`;
      btns.style.marginRight = "10px";
      btns.style.backgroundColor = "#0d63cb";
      btns.style.color = "white";
      btns.style.padding = "5px";
      btns.style.borderRadius = "5px";
      btns.style.borderColor = "#0d63cb";
      // @ts-ignore
      btns.onclick = () => this.handleSave("summarize");
      cont.appendChild(btns);

      const btnr = document.createElement("button");
      btnr.id = "rephrase";
      btnr.setAttribute("type", "button");
      btnr.innerHTML = "Rephrase text";
      btnr.className = `button`;
      btnr.style.marginRight = "10px";
      btnr.style.backgroundColor = "#0d63cb";
      btnr.style.color = "white";
      btnr.style.padding = "5px";
      btnr.style.borderRadius = "5px";
      btnr.style.borderColor = "#0d63cb";
      // @ts-ignore
      btnr.onclick = () => this.handleSave("reprhase");
      cont.appendChild(btnr);

      const btng = document.createElement("button");
      btng.id = "fix";
      btng.setAttribute("type", "button");
      btng.innerHTML = "Fix spelling & grammar";
      btng.className = `button`;
      btng.style.marginRight = "10px";
      btng.style.backgroundColor = "#0d63cb";
      btng.style.color = "white";
      btng.style.padding = "5px";
      btng.style.borderRadius = "5px";
      btng.style.borderColor = "#0d63cb";
      // @ts-ignore
      btng.onclick = () => this.handleSave("fix spelling and grammar");
      cont.appendChild(btng);

      const btnf = document.createElement("button");
      btnf.id = "friendly";
      btnf.setAttribute("type", "button");
      btnf.innerHTML = "Make it friendly";
      btnf.className = `button`;
      btnf.style.marginRight = "10px";
      btnf.style.backgroundColor = "#0d63cb";
      btnf.style.color = "white";
      btnf.style.padding = "5px";
      btnf.style.borderRadius = "5px";
      btnf.style.borderColor = "#0d63cb";

      // @ts-ignore
      btnf.onclick = () => this.handleSave("make it friendly");
      cont.appendChild(btnf);

      const btnfo = document.createElement("button");
      btnfo.id = "formal";
      btnfo.setAttribute("type", "button");
      btnfo.innerHTML = "Make it formal";
      btnfo.className = `button`;
      btnfo.style.marginRight = "10px";
      btnfo.style.backgroundColor = "#0d63cb";
      btnfo.style.color = "white";
      btnfo.style.padding = "5px";
      btnfo.style.borderRadius = "5px";
      btnfo.style.borderColor = "#0d63cb";
      // @ts-ignore
      btnfo.onclick = () => this.handleSave("make it formal");
      cont.appendChild(btnfo);

      const img = document.createElement("img");
      img.src = spinner;
      img.alt = "spinner";
      img.id = "spinner";
      img.height = 64;
      img.width = 64;
      img.style.visibility = "hidden";
      img.style.margin = "10px";

      cont.appendChild(img);

      return cont;
    },

    /**
     * Handle the main save task
     */
    handleSave(action) {
      // @ts-ignore
      document.getElementById("spinner").style.visibility = "visible";
      this.buttonsDisabled(true);
      const txtCont = document.getElementById("textContainer");
      txtCont && txtCont.remove();
      //  document.getElementById("spinner").style

      const { target } = this;
      // @ts-ignore
      const code = document.getElementById("txtPrompt").value.replaceAll('"', '/"'); // this.getCodeViewer().getContent();

      const opt = {
        method: "GET",
        url: "pages",
        headers: {
          "content-type": "application/json",
        },
      };

      opt.method = "GET";
      opt.url = `grapeai/GenerateText?action=${action}&input=${encodeURI(code)}`; //rephrased
      //const el = this.view.el; // target your div
      //el.src = spinner;
      client
        .request(opt)
        .then((response) => {
          const modalBody = modal.getContent();
          const cont = document.createElement("div");
          cont.id = "textContainer";
          cont.style.backgroundColor = "#FFF";
          cont.style.color = "#000";
          cont.style.padding = "5px";
          cont.style.width = "100%";
          cont.innerHTML = response.output;

          const btn = document.createElement("button");
          btn.id = "useText";
          btn.setAttribute("type", "button");
          btn.innerHTML = "Use text";
          btn.className = `button`;
          btn.style.marginTop = "10px";
          btn.style.backgroundColor = "#008000";
          btn.style.color = "white";
          btn.style.padding = "5px";
          btn.style.borderRadius = "5px";
          btn.style.borderColor = "#008000";
          btn.onclick = () => {
            target.set(
              keyCustomCode,
              `<span data="${code}" id="${keyCustomCode}">${response.output}</span>`
            );
            editor.Modal.close();
          };
          //cont.appendChild(btn);
          modalBody.appendChild(cont);
          modalBody.appendChild(btn);
          modal.setContent(modalBody);

          document.getElementById("spinner").style.visibility = "hidden";
          this.buttonsDisabled(false);
        })
        .catch((error) => {
          document.getElementById("spinner").style.visibility = "hidden";
          this.buttonsDisabled(false);
          console.log(error);
        });
    },

    buttonsDisabled(disabled) {
      try {
        document.getElementById("expand").disabled = disabled;
        document.getElementById("summarize").disabled = disabled;
        document.getElementById("rephrase").disabled = disabled;
        document.getElementById("fix").disabled = disabled;
        document.getElementById("friendly").disabled = disabled;
        document.getElementById("formal").disabled = disabled;
        document.getElementById("expand").style.visibility = disabled ? "hidden" : "visible";
        document.getElementById("summarize").style.visibility = disabled ? "hidden" : "visible";
        document.getElementById("rephrase").style.visibility = disabled ? "hidden" : "visible";
        document.getElementById("fix").style.visibility = disabled ? "hidden" : "visible";
        document.getElementById("friendly").style.visibility = disabled ? "hidden" : "visible";
        document.getElementById("formal").style.visibility = disabled ? "hidden" : "visible";
      } catch (e) {
        console.log(e);
      }
    },

    /**
     * Return the code viewer instance
     * @return {CodeViewer}
     */
    getCodeViewer() {
      // @ts-ignore
      if (!this.codeViewer) {
        // @ts-ignore
        this.codeViewer = editor.CodeManager.createViewer({
          codeName: "htmlmixed",
          theme: "hopscotch",
          readOnly: 0,
          ...options.codeViewOptions,
        });
      }
      // @ts-ignore
      return this.codeViewer;
    },

    ...options.commandCustomCode,
  });
};
