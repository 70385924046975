import client from "ApiClient";

export const CatalogItems = (editor, opts = {}) => {
  let selectedCatalog = 0;
  let selectedBlock = 0;
  let selectedItem = -1;
  editor.DomComponents.addType("catalog-items", {
    isComponent: (el) => el.tagName === "DIV" && el.classList?.contains("catalog-items"),
    model: {
      defaults: {
        traits: [
          {
            type: "select", // Type of the trait
            name: "catalogs", // (required) The name of the attribute/property to use on component
            label: "Catalog", // The label you will see in Settings
            options: opts.catalogs,
            default: opts.catalogs[0].id,
            changeProp: 1,
          },

          {
            type: "select", // Type of the trait
            name: "blocks", // (required) The name of the attribute/property to use on component
            label: "Block", // The label you will see in Settings
            options: [],
            placeholder: "Select a block",
            changeProp: 1,
          },
          {
            type: "select", // Type of the trait
            name: "items", // (required) The name of the attribute/property to use on component
            label: "Item", // The label you will see in Settings
            options: [],
            placeholder: "Select an item",
            changeProp: 1,
          },
        ],
      },
      style: {
        width: "100%",
        size: "100",
        textAlign: "center",
      },
      init: function () {
        if (opts.catalogs.length > 0) {
          this.on("change:catalogs", this.updateCatalogs);
          this.on("change:blocks", this.updateBlocks);
          this.on("change:items", this.updateItems);
          this.getTrait("catalogs").setValue(opts.catalogs[0].id);
          this.getTrait("catalogs").set("default", opts.catalogs[0].id);
          selectedCatalog = opts.catalogs[0].id;
        } else
          this.components(
            `<div class="catalog-items">No catalogs found. Please create a catalog in the catalog section</div>`
          );
      },
      updateCatalogs: function (model, value, el) {
        // console.log("selectedCatalog", value, selectedCatalog);
        //if (value === selectedCatalog) return;
        selectedCatalog = value;

        const options = {
          method: "GET",
          url: "catalogs",
          headers: {
            "content-type": "application/json",
          },
        };

        options.url = "blocks?cat_id=" + selectedCatalog;
        client
          .request(options)
          .then((response) => {
            //console.log("blocks", response);
            model.getTrait("blocks").set("options", []);
            response.map((item) => {
              model
                .getTrait("blocks")
                .set("options", [
                  ...model.getTrait("blocks").get("options"),
                  { id: item.blk_id, value: item.blk_id, label: item.blk_variable },
                ]);
            });
            selectedBlock = response[0].blk_id;
            this.getTrait("blocks").set("default", response[0].blk_id);

            options.url = "items?cat_id=" + selectedCatalog;
            client
              .request(options)
              .then((response) => {
                //console.log("items", response);
                model.getTrait("items").set("options", []);

                model
                  .getTrait("items")
                  .set("options", [
                    ...model.getTrait("items").get("options"),
                    { id: -1, label: "Suggested" },
                  ]);

                response.map((item) => {
                  model.getTrait("items").set("options", [
                    ...model.getTrait("items").get("options"),
                    {
                      id: item.item_id,
                      value: item.item_id,
                      label: item.item_name,
                      data: item.item_adc_id,
                    },
                  ]);
                });
                this.getTrait("items").set("default", response[0].item_id);
                //this.getTrait("blocks").set("default", selectedBlock);

                /*
                this.components(
                  `<div class="catalog-items">[suggested-${selectedCatalog}-${selectedBlock}]</div>`
                );*/
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });

        /*
        this.components(
          `<div class="catalog-items">[suggested-${selectedCatalog}-${model
            .getTrait("blocks")
            .getValue()}]</div>`
        );*/
      },
      updateBlocks: function (model, value) {
        selectedBlock = value;

        if (selectedItem == -1)
          this.components(
            `<div class="catalog-items" >[suggested-${selectedCatalog}-${selectedBlock}]</div>`
          );
        else
          this.components(
            `<div class="catalog-items">[single-${selectedItem}-${selectedBlock}]</div>`
          );
      },
      updateItems: function (model, value) {
        selectedItem = value;
        //this.getTrait("items").setValue(selectedItem);
        //console.log("updateItems", selectedItem, selectedCatalog, selectedBlock);

        if (selectedItem === "-1") {
          this.components(
            `<div class="catalog-items">[suggested-${selectedCatalog}-${selectedBlock}]</div>`
          );
        } else
          this.components(
            `<div class="catalog-items">[single-${selectedItem}-${selectedBlock}]</div>`
          );
      },
    },
    view: {
      events: {},
      init: function ({ model }) {
        // console.log("inti view", opts.catalogs);
        //if (selectedCatalog != 0) return;
        //this.listenTo(model, "change:attributes:chosenEvent", this.handleEventChange);
      },
    },
  });

  editor.BlockManager.add("CatalogItems", {
    tagName: "catalog-items",
    select: true,
    category: process.env.REACT_APP_SITE_TITLE + " AI",
    label: "Catalog items",
    media: `
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#2EA3F2"><path d="m159-168-34-14q-31-13-41.5-45t3.5-63l72-156v278Zm160 88q-33 0-56.5-23.5T239-160v-240l106 294q3 7 6 13.5t8 12.5h-40Zm206-4q-32 12-62-3t-42-47L243-622q-12-32 2-62.5t46-41.5l302-110q32-12 62 3t42 47l178 488q12 32-2 62.5T827-194L525-84Zm-86-476q17 0 28.5-11.5T479-600q0-17-11.5-28.5T439-640q-17 0-28.5 11.5T399-600q0 17 11.5 28.5T439-560Zm58 400 302-110-178-490-302 110 178 490ZM319-650l302-110-302 110Z"/></svg>  
    `,
    content: `<div class="catalog-items">Select an item from their catalogs or just let the AI ​​do it</div>`,
  });
};
