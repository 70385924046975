class StorageManager {
  constructor (key) {
    this.key = key;
  }
  save(obj) {
    const jsonString = window.btoa(JSON.stringify(obj));
    localStorage.setItem(this.key, jsonString);
  }
  get() {
    const jsonString = localStorage.getItem(this.key);
    return jsonString ? JSON.parse(window.atob(jsonString)) : null;
  }
  remove() {
    localStorage.removeItem(this.key);
  }
}

export default StorageManager;