import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import theme from "assets/theme";
import EmailEditor from "react-email-editor";
import client from "ApiClient";
import AddIcon from "@mui/icons-material/Add";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import MUIDataTable from "mui-datatables";
import { useEffect, useRef, useState } from "react";
import { Card } from "@material-ui/core";
import CustomToolbarSelect from "./customToolbarSelect";

/* eslint-disable */
export default function HtmlBlocks(props) {
  const [isUpdated, setIsUpdated] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const closeAlert = () => setOpenAlert(false);
  const [isLoading, setIsLoading] = useState(false);
  const [htmlBlocksData, setHtmlBlocksData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [name, setName] = useState("");
  const emailEditorRef = useRef(null);

  const [margin, setMargin] = useState(300);
  const [controller] = useMaterialUIController();
  const { miniSidenav } = controller;

  useEffect(() => {
    if (miniSidenav) setMargin(0);
    else setMargin(300);
  }, [miniSidenav]);

  document.addEventListener("focusin", (e) => {
    if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
      e.stopImmediatePropagation();
    }
  });

  const options = {
    method: "GET",
    url: "blocks",
    headers: {
      "content-type": "application/json",
    },
  };

  const getData = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `blocks?cat_id=` + props.catID;

    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setHtmlBlocksData(response);

        const unlayer = emailEditorRef.current?.editor;

        if (unlayer != undefined && response != "" && response[0] != undefined) {
          setSelectedData(response[0]);

          unlayer.registerCallback("previewHtml", async function (params, done) {
            const options = {
              method: "POST",
              url: `emailCopy/Preview`,
              headers: {
                "content-type": "application/json",
              },
            };
            options.data = JSON.stringify({
              subject: "",
              body: "[suggested-" + props.catID + "-" + response[0].blk_id + "]",
            });
            client
              .request(options)
              .then((response) => {
                done({
                  html: response.body,
                });
              })
              .catch((error) => {
                setIsLoading(false);
                console.log(error);
              });
          });

          if (response[0].blk_json == "" && response[0].blk_html == "") {
            unlayer?.loadBlank({
              backgroundColor: "#e7e7e7",
            });
          } else {
            if (response[0].blk_json != "") {
              //setJsonDesign(JSON.parse(htmlBlocksData[index.dataIndex].blk_json));
              unlayer?.loadDesign(JSON.parse(response[0].blk_json));
            } else {
              unlayer.loadDesign({
                html: response[0].blk_html,
                classic: true,
              });
            }
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        // eslint-disable-next-line
        console.log(error);
      });
  };

  useEffect(() => {
    if (props.catID) getData();
  }, [props.catID]);

  const handleSave = async () => {
    setIsLoading(true);
    options.method = "POST";
    options.url = "blocks";

    options.data = JSON.stringify({
      blk_variable: name,
      blk_html: "",
      blk_json: "",
      blk_status: 0,
      blk_cat_id: props.catID,
    });

    client
      .request(options)
      .then(() => {
        setIsLoading(true);
        setOpenAlert(true);
        setOpenAdd(false);
        setName("");
        getData();
      })
      .catch((error) => {
        setIsSaving(false);
        // eslint-disable-next-line
      });
  };

  const handleDelete = () => {
    setIsLoading(true);
    options.method = "DELETE";
    options.url = `blocks?blk_id=` + selectedData.blk_id;
    options.headers = {
      "content-type": "application/json",
    };
    console.log(options.data);
    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        getData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const handleUpdate = async () => {
    setIsLoading(true);

    const unlayer = emailEditorRef.current?.editor;
    unlayer.exportHtml(function (data) {
      var design = data.design; // design json
      var html = data.chunks.body; // design html
      options.method = "PUT";
      options.url = "blocks";
      options.data = JSON.stringify({
        blk_id: selectedData.blk_id,
        blk_user_id: selectedData.blk_user_id,
        blk_variable: selectedData.blk_variable,
        blk_html: html,
        blk_json: JSON.stringify(design),
        blk_status: selectedData.blk_status,
        blk_cat_id: props.catID,
      });

      client
        .request(options)
        .then(() => {
          setIsLoading(false);
          setIsUpdated(false);
          getData();
        })
        .catch((error) => {
          console.log(error);
          // eslint-disable-next-line
        });
    });
  };

  const customToolbarSelect = () => <CustomToolbarSelect onDelete={handleDelete} />;

  const CustomToolbar = ({ displayData }) => {
    return (
      <IconButton
        onClick={() => {
          setOpenAdd(true);
        }}
        sx={{ fontWeight: "bold" }}
      >
        <Tooltip id="button-add" title="Create a new block">
          <AddIcon />
        </Tooltip>
      </IconButton>
    );
  };

  const columns = [
    {
      label: "HTML blocks",
      name: "blk_variable",
      options: {
        filter: true,
      },
    },
  ];

  const tableOptions = {
    textLabels: {
      body: {
        noMatch: "Sorry, no HTML blocks records found",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "Clear filter",
      },
      filterType: "dropdown",
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "HTML blocks selected",
      },
    },
    print: false,
    pagination: false,
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    selectableRowsOnClick: true,
    responsive: "simple",
    filterType: "dropdown",
    selectableRows: "single",
    fixedHeader: true,
    fixedSelectColumn: true,
    searchOpen: false,
    searchAlwaysOpen: false,
    searchPlaceholder: "Search",
    customToolbarSelect: customToolbarSelect,
    customToolbar: CustomToolbar,
    onRowSelectionChange: (curRowSelected, allRowsSelected) => {
      setIsUpdated(false);
      setSelectedData(htmlBlocksData[curRowSelected[0].dataIndex]);

      const unlayer = emailEditorRef.current?.editor;

      unlayer.registerCallback("previewHtml", async function (params, done) {
        const options = {
          method: "POST",
          url: `emailCopy/Preview`,
          headers: {
            "content-type": "application/json",
          },
        };
        options.data = JSON.stringify({
          subject: "",
          body:
            "[suggested-" +
            props.catID +
            "-" +
            htmlBlocksData[curRowSelected[0].dataIndex].blk_id +
            "]",
        });
        client
          .request(options)
          .then((response) => {
            done({
              html: response.body,
            });
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      });

      if (
        htmlBlocksData[curRowSelected[0].dataIndex].blk_json == "" &&
        htmlBlocksData[curRowSelected[0].dataIndex].blk_html == ""
      ) {
        unlayer?.loadBlank({
          backgroundColor: "#e7e7e7",
        });
      } else {
        if (htmlBlocksData[curRowSelected[0].dataIndex].blk_json != "") {
          //setJsonDesign(JSON.parse(htmlBlocksData[index.dataIndex].blk_json));
          unlayer?.loadDesign(JSON.parse(htmlBlocksData[curRowSelected[0].dataIndex].blk_json));
        } else {
          unlayer.loadDesign({
            html: htmlBlocksData[curRowSelected[0].dataIndex].blk_html,
            classic: true,
          });
        }
      }
    },
  };

  const onReady = (unlayer) => {
    unlayer.registerCallback("design:updated", async function (updates) {
      setIsUpdated(true);
    });
  };

  return (
    <Dialog
      open={props.openModal}
      onClose={(e, reason) => {
        reason === "backdropClick" ? null : props.onClose();
      }}
      fullScreen
      disableEscapeKeyDown
      style={{ marginLeft: margin }}
      sx={{
        //You can copy the code below in your theme
        background: "#F4F0F700",
        "& .MuiPaper-root": {
          background: theme.palette.background.default,
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent", // Try to remove this to see the result
        },
      }}
    >
      <MDBox
        mx={2}
        mt={1}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="xs"
        coloredShadow="info"
      >
        <MDTypography variant="h2" color="white">
          HTML blocks for catalog {props.catName}
          {isLoading && (
            <CircularProgress size={30} style={{ marginLeft: 15, position: "relative", top: 4 }} />
          )}
        </MDTypography>
      </MDBox>
      <DialogContent>
        <MDBox pb={3} pt={2}>
          <Grid container spacing={6}>
            <Grid item xs={11}>
              {openAdd && (
                <Grid container spacing={1} m={2}>
                  <Grid item xs={10}>
                    <TextField
                      type="text"
                      label="Block name"
                      fullWidth
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <MDButton
                      autoFocus
                      onClick={() => {
                        setName("");
                        setOpenAdd(false);
                      }}
                      disabled={isLoading}
                      variant="gradient"
                      color="secondary"
                    >
                      Cancel
                    </MDButton>

                    <MDButton
                      onClick={handleSave}
                      disabled={isLoading || name == ""}
                      autoFocus
                      style={{ marginLeft: 15 }}
                      variant="gradient"
                      color="success"
                    >
                      Save
                      {isLoading && (
                        <CircularProgress
                          size={24}
                          style={{ marginLeft: 15, position: "relative", top: 4 }}
                        />
                      )}
                    </MDButton>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={1} m={2}>
                <Grid item xs={3}>
                  <MUIDataTable
                    data={htmlBlocksData}
                    columns={columns}
                    options={tableOptions}
                    minHeight={600}
                  />
                </Grid>

                <Grid item xs={9}>
                  <Card style={{ padding: 5 }}>
                    <MDTypography>
                      HTML code for {selectedData != undefined ? selectedData.blk_variable : ""}
                    </MDTypography>
                    {isUpdated && (
                      <Tooltip title="Update for preview">
                        <MDButton onClick={handleUpdate} autoFocus variant="gradient" color="info">
                          Update
                          {isLoading && (
                            <CircularProgress
                              size={24}
                              style={{ marginLeft: 15, position: "relative", top: 4 }}
                            />
                          )}
                        </MDButton>
                      </Tooltip>
                    )}
                    <EmailEditor
                      ref={emailEditorRef}
                      onReady={onReady}
                      minHeight={600}
                      projectId={localStorage.getItem("unlayerID")}
                      appearance={{
                        panels: {
                          tools: {
                            dock: "left",
                            tabs: {
                              body: {
                                visible: true,
                              },
                            },
                          },
                        },
                        features: {
                          preview: true,
                        },
                      }}
                      tabs={{
                        image: {
                          enabled: false,
                        },
                      }}
                      options={{
                        customJS: [
                          window.location.protocol +
                            "//" +
                            window.location.host +
                            "/customVariablesTool.js",
                        ],
                        editor: {
                          autoSelectOnDrop: true,
                        },
                      }}
                    />
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </DialogContent>
      <DialogActions style={{ color: "white", backgroundColor: "white" }} marginLeft={2}>
        <MDButton autoFocus onClick={() => props.onClose()} variant="gradient" color="secondary">
          Close
        </MDButton>
      </DialogActions>
      <MDSnackbar
        color="success"
        icon="success"
        title="HTML block updated"
        content=""
        dateTime=""
        open={openAlert}
        onClose={closeAlert}
        close={closeAlert}
        bgWhite
      />
    </Dialog>
  );
}
