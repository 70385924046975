import { useEffect, useRef, useState } from "react";
// react-router-dom components
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import client from "ApiClient";
import SimpleReactValidator from "simple-react-validator";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Select,
} from "@mui/material";
import MDButton from "components/MDButton";
import csc from "country-state-city";
import { InputAdornment, ListItemText, MenuItem } from "@material-ui/core";

/* eslint-disable */
function Edit(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [fromName, setFromName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [senderDomain, setSenderDomain] = useState(-1);
  const [arrSenderDomains, setArrSenderDomains] = useState([]);
  const [fromEmail, setFromEmail] = useState("");
  const [replyTo, setReplyTo] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("Select a country");
  const { openModal, closeModal } = props;
  const closeErrorSB = () => setErrorSB(false);
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(new SimpleReactValidator());
  const countries = csc.getAllCountries();
  const clearVariables = () => {
    setFromName("");
    setCompanyName("");
    setSenderDomain(-1);
    setFromEmail("");
    setReplyTo("");
    setStreet("");
    setCity("");
    setState("");
    setZip("");
    setCountry("Select a country");
    simpleValidator.current.hideMessages();
    props.onClose();
  };

  const handleSave = () => {
    if (simpleValidator.current.allValid()) {
      setIsLoading(true);
      const options = {
        method: "PUT",
        url: `senderProfiles`,
        headers: {
          "content-type": "application/json",
        },
      };

      options.data = JSON.stringify({
        sep_id: props.profile.sep_id,
        sep_name: fromName,
        sep_displayFrom: fromName,
        sep_company: companyName,
        sep_sed_id: senderDomain,
        sep_envelopFrom:
          fromEmail +
          "@" +
          arrSenderDomains.filter((item) => item.sed_id == senderDomain)[0].sed_sendingDomain,
        sep_replyTo:
          replyTo +
          "@" +
          arrSenderDomains.filter((item) => item.sed_id == senderDomain)[0].sed_sendingDomain,
        sep_address: street,
        sep_city: city,
        sep_state: state,
        sep_zip: zip,
        sep_country: country,
        sep_status: props.profile.sep_status,
        sep_user_id: props.profile.sep_user_id,
      });
      console.log(options.data);
      client
        .request(options)
        .then((response) => {
          setIsLoading(false);
          props.onDone();
          clearVariables();
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } else {
      setIsLoading(false);
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const getSenderDomains = async () => {
    const options = {
      method: "GET",
      url: `senderDomains?verified=true`,
      headers: {
        "content-type": "application/json",
      },
    };

    await client
      .request(options)
      .then((response) => {
        setArrSenderDomains(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getSenderDomains();
  }, []);

  useEffect(() => {
    if (props.profile != null) {
      setFromName(props.profile.sep_name);
      setCompanyName(props.profile.sep_company);
      setFromEmail(props.profile.sep_envelopFrom.split("@")[0]);
      setReplyTo(props.profile.sep_replyTo.split("@")[0]);
      setStreet(props.profile.sep_address);
      setCity(props.profile.sep_city);
      setState(props.profile.sep_state);
      setZip(props.profile.sep_zip);
      setCountry(props.profile.sep_country);
      setSenderDomain(props.profile.sep_sed_id);
    }
  }, [props]);

  return (
    <Dialog open={openModal} onClose={clearVariables}>
      <MDBox
        variant="gradient"
        bgColor="primary"
        borderRadius="xs"
        coloredShadow="primary"
        p={2}
        textAlign="center"
      >
        <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
          Edit sender profile
        </MDTypography>
      </MDBox>
      <DialogContent>
        <MDBox pt={4} pb={3} px={2}>
          <MDBox pt={2} pb={2}>
            <MDInput
              type="text"
              placeholder="Enter from name"
              label="From Name"
              value={fromName}
              required
              fullWidth
              onChange={(e) => setFromName(e.target.value)}
            />
            <MDBox color="red">
              {simpleValidator.current.message("from name", fromName, "required")}
            </MDBox>
          </MDBox>
          <MDBox pt={2} pb={2}>
            <MDInput
              fullWidth
              required
              type="text"
              placeholder="Enter your company name"
              label="Company name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
            <MDBox color="red">
              {simpleValidator.current.message("Company name", companyName, "required")}
            </MDBox>
          </MDBox>
          {arrSenderDomains.length > 0 && (
            <MDBox pt={2} pb={2}>
              <Select
                placeholder="Select sender domain"
                value={senderDomain}
                onChange={(e) => {
                  setSenderDomain(e.target.value);
                }}
              >
                <MenuItem key={-1} value={-1}>
                  <ListItemText primary={"Select a sender domain"} />
                </MenuItem>
                {arrSenderDomains.map((item) => (
                  <MenuItem key={item.sed_id} value={item.sed_id}>
                    <ListItemText primary={item.sed_sendingDomain} />
                  </MenuItem>
                ))}
              </Select>
            </MDBox>
          )}
          <MDBox pt={2} pb={2}>
            <MDInput
              fullWidth
              required
              type="text"
              placeholder="Enter email address"
              label="From email"
              value={fromEmail}
              onChange={(e) => {
                setFromEmail(e.target.value);
                setReplyTo(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <MDBox style={{ backgroundColor: "text" }}>
                      @
                      {senderDomain < 0
                        ? "sender domain"
                        : arrSenderDomains?.filter((item) => item.sed_id == senderDomain)[0]?.sed_sendingDomain}
                    </MDBox>
                  </InputAdornment>
                ),
              }}
            />
            <MDBox color="red">
              {simpleValidator.current.message("From email", fromEmail, "required")}
            </MDBox>
          </MDBox>
          <MDBox pt={2} pb={2}>
            <MDInput
              fullWidth
              required
              type="text"
              placeholder="Enter email address"
              label="Reply-to"
              value={replyTo}
              onChange={(e) => setReplyTo(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    @
                    {senderDomain < 0
                      ? "sender domain"
                      : arrSenderDomains?.filter((item) => item.sed_id == senderDomain)[0]?.sed_sendingDomain}
                  </InputAdornment>
                ),
              }}
            />
            <MDBox color="red">
              {simpleValidator.current.message("Reply to", replyTo, "required")}
            </MDBox>
            <MDTypography variant="caption" color="text">
              To increase inboxing and to prevent your emails from going to spam, we highly recommend
              that the reply to address is always the same as the from email.
            </MDTypography>
          </MDBox>

          <MDBox pt={2} pb={2}>
            <MDInput
              fullWidth
              required
              type="text"
              placeholder="Enter street address"
              label="Street"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
            />
            <MDBox color="red">
              {simpleValidator.current.message("street", street, "required")}
            </MDBox>
          </MDBox>

          <MDBox pt={2} pb={2}>
            <MDInput
              fullWidth
              required
              type="text"
              placeholder="Enter city"
              label="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <MDBox color="red">{simpleValidator.current.message("City", city, "required")}</MDBox>
          </MDBox>

          <MDBox pt={2} pb={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <MDInput
                  fullWidth
                  required
                  type="text"
                  placeholder="Enter state"
                  label="State"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
                <MDBox color="red">
                  {simpleValidator.current.message("State", state, "required")}
                </MDBox>
              </Grid>
              <Grid item xs={6}>
                <MDInput
                  fullWidth
                  required
                  type="text"
                  placeholder="Enter zip"
                  label="Zip"
                  value={zip}
                  onChange={(e) => setZip(e.target.value)}
                />
                <MDBox color="red">{simpleValidator.current.message("Zip", zip, "required")}</MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox pt={2} pb={2}>
            <Select
              placeholder="Select a country"
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
            >
              <MenuItem key={"Select a country"} value={"Select a country"}>
                <ListItemText primary={"Select a country"} />
              </MenuItem>
              {countries.map((item) => (
                <MenuItem key={item.sortname} value={item.name}>
                  <ListItemText primary={item.name} />
                </MenuItem>
              ))}
            </Select>
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDButton
          onClick={clearVariables}
          disabled={isLoading}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </MDButton>
        <MDButton onClick={handleSave} disabled={isLoading} variant="gradient" color="success">
          Save
          {isLoading && (
            <CircularProgress size={24} style={{ marginLeft: 15, position: "relative", top: 4 }} />
          )}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
export default Edit;
