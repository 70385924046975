import client from "ApiClient";
import React from "react";

export const FeatureFlags = React.createContext({});

/*eslint-disable*/
export function FeatureFlagsProvider({ children }) {
  const [features, setFeatures] = React.useState({
    isGooglePayEnabled: false,
    plName: "sendpad", //sendpad
    hiddenMenus: ["catalogs", "series", "blogs", "automation", "sales"],
    colorBackground: "#F4F0F7",
    colorPrimary: "#735AC7",
    colorPrimaryFocus: "#735AC7",
    colorPrimaryGradient: "#735AC7",
    colorPrimaryGradientState: "#998FC7",
    subscribersFields: [
      "Email",
      "First",
      "Last",
      "City",
      "State",
      "Country",
      "Tags",
      "Optin date",
      "Date of birth",
      "Gender",
      "Phone",
      "Zip",
      "Source",
      "Optin IP",
      "Education",
      "Children",
      "Ethnicity",
      "Employment",
    ],
  });

  /*SENDPAD*/
  switch (process.env.REACT_APP_PLNAME) {
    case "sendpad":
      features.plName = "sendpad";
      features.hiddenMenus = ["catalogs", "series", "blogs", "automation", "sales"];
      features.colorBackground = "#F4F0F7";
      features.colorPrimary = "#735AC7";
      features.colorPrimaryFocus = "#735AC7";
      features.colorPrimaryGradient = "#735AC7";
      features.colorPrimaryGradientState = "#998FC7";
      features.subscribersFields = [
        "Email",
        "First",
        "Last",
        "City",
        "State",
        "Country",
        "Tags",
        "Optin date",
        "Date of birth",
        "Gender",
        "Phone",
        "Zip",
        "Source",
        "Optin IP",
        "Custom Fields",
      ];
      break;
    case "mailsense":
      features.plName = "mailsense";
      features.hiddenMenus = [];
      features.colorBackground = "#F2F2F3";
      features.colorPrimary = "#2773B5";
      features.colorPrimaryFocus = "#2773B5";
      features.colorPrimaryGradient = "#2773B5";
      features.colorPrimaryGradientState = "#2EA3F2";
      features.subscribersFields = [
        "Email",
        "First",
        "Last",
        "City",
        "State",
        "Country",
        "Tags",
        "Optin date",
        "Date of birth",
        "Gender",
        "Phone",
        "Zip",
        "Source",
        "Optin IP",
        "Education",
        "Children",
        "Ethnicity",
        "Employment",
        "Custom Fields",
      ];
      break;
  }

  /*all features*/
  /*
  [
    "Email",
    "First",
    "Last",
    "City",
    "State",
    "Country",
    "Tags",
    "Optin date",
    "Date of birth",
    "Gender",
    "Phone",
    "Zip",
    "Source",
    "Optin IP",
    "Education",
    "Children",
    "Ethnicity",
    "Employment",
    "Custom Fields",
  ];
  */
  /*end all features*/

  const getFeatures = () => {
    const options = {
      method: "GET",
      url: "users/getFeatures",
    };
    client.request(options).then((response) => {
      if (response != undefined) setFeatures(response);
    });
  };

  /*
  React.useEffect(() => {
    getFeatures;
  }, []);
  */

  return <FeatureFlags.Provider value={{ features }}>{children}</FeatureFlags.Provider>;
}

export const useFeatureFlags = () => React.useContext(FeatureFlags);
