/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useRef, useState, React, Fragment, useEffect, useContext } from "react";
import theme from "assets/theme";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import client from "ApiClient";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Moment from "moment";
import {
  Autocomplete,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  // IconButton,
  Input,
  // InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import SimpleReactValidator from "simple-react-validator";
import csc from "country-state-city";
// import MDInput from "components/MDInput";
import PublishIcon from "@mui/icons-material/Publish";
import WarningIcon from "@material-ui/icons/Warning";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { createFilterOptions } from "@mui/base";
import { Step, StepContent, StepButton, Stepper, StepLabel } from "@material-ui/core";
import { useMaterialUIController } from "context";
import { FeatureFlags } from "context/FeatureFlags";
import MSAddCustomFields from "components/MSAddCustomFields";

/* eslint-disable */
function AddSubscriber(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isMapping, setIsMapping] = useState(false);
  const [failUpload, setFailUpload] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [inferFromZip, setInferFromZip] = useState(false);
  const [inferFromName, setInferFromName] = useState(false);
  const [updateRecords, setUpdateRecords] = useState(true);
  const [addCustomFields, setAddCustomFields] = useState(false);
  const [addRecords, setAddRecords] = useState(true);
  const [completeLastEngagementDate, setCompleteLastEngagementDate] = useState(false);
  const [completeOptinDate, setcompleteOptinDate] = useState(false);
  const [filePath, setFilePath] = useState("");
  const [headers, setHeaders] = useState(null);
  const [mappedHeaders, setMappedHeaders] = useState([]);
  const [selectedHeader, setSelectedHeader] = useState("");
  const [selectedFullname, setSelectedFullname] = useState("");
  const [selectedAge, setSelectedAge] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [source, setSource] = useState("");
  const [customFields, setCustomFields] = useState([]);
  const [fieldRows, setFieldRows] = useState([]);
  const { openModal, closeModal } = props;
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [selectedFile, setSelectedFile] = useState(null);
  // const [lastEngagementDate, setLastEngagementDate] = useState(Moment());
  const [optinDate, setOptinDate] = useState(Moment());
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [skippedSteps, setSkippedSteps] = useState([]);
  const [failedSteps, setFailedSteps] = useState([]);
  const countries = csc.getAllCountries();

  const [mappingResponse, setMappingResponse] = useState(null);

  const [tags, setTags] = useState([]);
  const [suggestionsTag, setSuggestionsTag] = useState([]);
  const filter = createFilterOptions();
  const [margin, setMargin] = useState(300);
  const [controller] = useMaterialUIController();
  const { miniSidenav } = controller;

  const { features } = useContext(FeatureFlags);

  const steps = features.subscribersFields;

  useEffect(() => {
    if (miniSidenav) setMargin(0);
    else setMargin(300);
  }, [miniSidenav]);

  let mappedField = useState({
    email: "",
    first: "",
    last: "",
    fullname: "",
    city: "",
    state: "",
    country: "",
    tags: "",
    optinDate: "",
    dateOfBirth: "",
    age: "",
    gender: "",
    phone: "",
    zip: "",
    source: "",
    optinip: "",
    education: "",
    children: "",
    ethnicity: "",
    employment: "",
  });

  const clearVariables = (reason) => {
    if (reason === "backdropClick") return;
    setFailUpload(false);
    setIsSelected(false);
    setFilePath("");
    setHeaders(null);
    setMappedHeaders([]);
    setSelectedFile(null);
    setSelectedHeader("");
    setSelectedFullname("");
    setFieldRows([]);
    setActiveStep(0);
    setSkipped(new Set());
    setFailedSteps([]);
    setMappingResponse(null);
    setSource("");
    mappedField = {
      email: "",
      first: "",
      last: "",
      fullname: "",
      city: "",
      state: "",
      country: "",
      tags: "",
      optinDate: "",
      dateOfBirth: "",
      age: "",
      gender: "",
      phone: "",
      zip: "",
      source: "",
      optinip: "",
      education: "",
      children: "",
      ethnicity: "",
      employment: "",
    };
    closeModal();
  };

  const mappedFields = () => {
    setIsMapping(true);
    try {
      const options = {
        method: "POST",
        url: `subscribers/mappedFields`,
        headers: {
          "content-type": "application/json",
        },
      };
      const mapped = [
        { csv_field_name: mappedField[0].email, normalized_field_name: "email" },
        { csv_field_name: mappedField[0].first, normalized_field_name: "first" },
        { csv_field_name: mappedField[0].last, normalized_field_name: "last" },
        { csv_field_name: mappedField[0].fullname, normalized_field_name: "fullname" },
        { csv_field_name: mappedField[0].city, normalized_field_name: "city" },
        { csv_field_name: mappedField[0].state, normalized_field_name: "state" },
        { csv_field_name: mappedField[0].country, normalized_field_name: "country" },
        { csv_field_name: mappedField[0].tags, normalized_field_name: "tags" },
        { csv_field_name: mappedField[0].optinDate, normalized_field_name: "optinDate" },
        { csv_field_name: mappedField[0].dateOfBirth, normalized_field_name: "dateOfBirth" },
        { csv_field_name: mappedField[0].age, normalized_field_name: "age" },
        { csv_field_name: mappedField[0].gender, normalized_field_name: "gender" },
        { csv_field_name: mappedField[0].phone, normalized_field_name: "phone" },
        { csv_field_name: mappedField[0].zip, normalized_field_name: "zip" },
        { csv_field_name: mappedField[0].source, normalized_field_name: "source" },
        { csv_field_name: mappedField[0].optinip, normalized_field_name: "optinIP" },
        { csv_field_name: mappedField[0].education, normalized_field_name: "education" },
        { csv_field_name: mappedField[0].children, normalized_field_name: "children" },
        { csv_field_name: mappedField[0].ethnicity, normalized_field_name: "ethnicity" },
        { csv_field_name: mappedField[0].employment, normalized_field_name: "employment" },
      ];

      if (customFields.length > 0) {
        customFields.map((field) => {
          mapped.push({
            csv_field_name: field.mappedField,
            custom_field_name: field.name,
            custom_field_type: field.type,
            groupable: field.groupable,
          });
        });
      }

      options.data = JSON.stringify({
        mapped_fields: mapped,
        uploadedFilePath: filePath,
      });

     // console.log(options.data);
      client
        .request(options)
        .then((response) => {
          setIsMapping(false);
          setMappingResponse(response);
        })
        .catch((error) => {
          setIsMapping(false);
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  // let mappedField = [];
  const isStepOptional = (step) => {
    return skippedSteps.includes(step);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const isStepFailed = (step) => {
    return failedSteps.includes(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    // setMappedHeaders(mappedHeaders.filter((item) => !mappedField.includes(item) && item != selectedHeader));
    /*
    switch (activeStep) {
      case 0:
        mappedField[0].email = selectedHeader;
        break;
      case 1:
        if (selectedHeader === "") mappedField[0].fullname = selectedFullname;
        else mappedField[0].first = selectedHeader;
        break;
      case 2:
        mappedField[0].last = selectedHeader;
        break;
      case 3:
        mappedField[0].city = selectedHeader;
        break;
      case 4:
        mappedField[0].state = selectedHeader;
        break;
      case 5:
        mappedField[0].country = selectedHeader;
        break;
      case 6:
        mappedField[0].tags = selectedHeader;
        break;
      case 7:
        mappedField[0].optinDate = selectedHeader;
        break;
      case 8:
        if (selectedHeader === "") mappedField[0].age = selectedAge;
        else mappedField[0].dateOfBirth = selectedHeader;
        break;
      case 9:
        mappedField[0].gender = selectedHeader;
        break;
      case 10:
        mappedField[0].phone = selectedHeader;
        break;
      case 11:
        mappedField[0].zip = selectedHeader;
        break;
      case 12:
        mappedField[0].source = selectedHeader;
        break;
      case 13:
        mappedField[0].optinip = selectedHeader;
        break;
      case 14:
        mappedField[0].education = selectedHeader;
        break;
      case 15:
        mappedField[0].children = selectedHeader;
        break;
      case 16:
        mappedField[0].ethnicity = selectedHeader;
        break;
      case 17:
        mappedField[0].employment = selectedHeader;
        break;
    }
        */

    setSelectedHeader("");
    if (activeStep === steps.length - 1 && mappedField[0].email != "") {
      mappedFields();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    /*
    switch (activeStep) {
      case 0:
        mappedField[0].email = selectedHeader;
        break;
      case 1:
        if (selectedHeader === "") mappedField[0].fullname = selectedFullname;
        else mappedField[0].first = selectedHeader;
        break;
      case 2:
        mappedField[0].last = selectedHeader;
        break;
      case 3:
        mappedField[0].city = selectedHeader;
        break;
      case 4:
        mappedField[0].state = selectedHeader;
        break;
      case 5:
        mappedField[0].country = selectedHeader;
        break;
      case 6:
        mappedField[0].tags = selectedHeader;
        break;
      case 7:
        mappedField[0].optinDate = selectedHeader;
        break;
      case 8:
        if (selectedHeader === "") mappedField[0].age = selectedAge;
        else mappedField[0].dateOfBirth = selectedHeader;
        break;
      case 9:
        mappedField[0].gender = selectedHeader;
        break;
      case 10:
        mappedField[0].phone = selectedHeader;
        break;
      case 11:
        mappedField[0].zip = selectedHeader;
        break;
      case 12:
        mappedField[0].source = selectedHeader;
        break;
      case 13:
        mappedField[0].optinip = selectedHeader;
        break;
      case 14:
        mappedField[0].education = selectedHeader;
        break;
      case 15:
        mappedField[0].children = selectedHeader;
        break;
      case 16:
        mappedField[0].ethnicity = selectedHeader;
        break;
      case 17:
        mappedField[0].employment = selectedHeader;
        break;
    }
*/
    setSelectedHeader("");
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleChangeHeader = (event) => {
    const header = event.target.value;
    setSelectedHeader(header);
    switch (activeStep) {
      case 0:
        mappedField[0].email = header;
        break;
      case 1:
        mappedField[0].first = header;
        break;
      case 2:
        mappedField[0].last = header;
        break;
      case 3:
        mappedField[0].city = header;
        break;
      case 4:
        mappedField[0].state = header;
        break;
      case 5:
        mappedField[0].country = header;
        break;
      case 6:
        mappedField[0].tags = header;
        break;
      case 7:
        mappedField[0].optinDate = header;
        break;
      case 8:
        mappedField[0].dateOfBirth = header;
        break;
      case 9:
        mappedField[0].gender = header;
        break;
      case 10:
        mappedField[0].phone = header;
        break;
      case 11:
        mappedField[0].zip = header;
        break;
      case 12:
        mappedField[0].source = header;
        break;
      case 13:
        mappedField[0].optinip = header;
        break;
      case 14:
        mappedField[0].education = header;
        break;
      case 15:
        mappedField[0].children = header;
        break;
      case 16:
        mappedField[0].ethnicity = header;
        break;
      case 17:
        mappedField[0].employment = header;
        break;
    }
  };

  const handleChangeFullName = (event) => {
    const fullname = event.target.value;
    setSelectedFullname(fullname);
    mappedField[0].fullname = fullname;
  };

  const handleChangeAge = (event) => {
    const age = event.target.value;
    setSelectedAge(age);
    mappedField[0].age = age;
  };

  const handleChangeCountry = (event) => {
    setSelectedCountry(event.target.value);
  };

  const uploadFile = () => {
    if (simpleValidator.current.allValid()) {
      setIsLoading(true);
      try {
        const options = {
          method: "POST",
          url: `subscribers/uploadfile`,
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const formData = new FormData();
        formData.append("file", selectedFile);
        options.data = formData;
        client
          .request(options)
          .then((response) => {
            setIsLoading(false);
            setFilePath(response);
            validateFile(response);
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const validateFile = (file) => {
    setIsLoading(true);
    try {
      const options = {
        method: "POST",
        url: `subscribers/GetCSVFields?uploadedFilePath=` + file,
      };
      client
        .request(options)
        .then((response) => {
          setIsLoading(false);
          if (response !== "" && response !== null && response.rows.length > 0) {
            setFailUpload(false);
            const head = response.headers.filter((item) => item.trim() != "");
            setHeaders(head);
            setMappedHeaders(head);
            setFieldRows(response.rows);
          } else {
            setFailUpload(true);
            setSelectedFile(null);
            setIsSelected(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = () => {
    setIsLoading(true);

    try {
      const options = {
        method: "POST",
        url: `subscribers/commit`,
        headers: {
          "content-type": "application/json",
        },
      };

      const mapped = [
        { csv_field_name: mappedField[0].email, normalized_field_name: "email" },
        { csv_field_name: mappedField[0].first, normalized_field_name: "first" },
        { csv_field_name: mappedField[0].last, normalized_field_name: "last" },
        { csv_field_name: mappedField[0].fullname, normalized_field_name: "fullname" },
        { csv_field_name: mappedField[0].city, normalized_field_name: "city" },
        { csv_field_name: mappedField[0].state, normalized_field_name: "state" },
        { csv_field_name: mappedField[0].country, normalized_field_name: "country" },
        { csv_field_name: mappedField[0].tags, normalized_field_name: "tags" },
        { csv_field_name: mappedField[0].optinDate, normalized_field_name: "optinDate" },
        { csv_field_name: mappedField[0].dateOfBirth, normalized_field_name: "dateOfBirth" },
        { csv_field_name: mappedField[0].age, normalized_field_name: "age" },
        { csv_field_name: mappedField[0].gender, normalized_field_name: "gender" },
        { csv_field_name: mappedField[0].phone, normalized_field_name: "phone" },
        { csv_field_name: mappedField[0].zip, normalized_field_name: "zip" },
        { csv_field_name: mappedField[0].source, normalized_field_name: "source" },
        { csv_field_name: mappedField[0].optinip, normalized_field_name: "optinIP" },
        { csv_field_name: mappedField[0].education, normalized_field_name: "education" },
        { csv_field_name: mappedField[0].children, normalized_field_name: "children" },
        { csv_field_name: mappedField[0].ethnicity, normalized_field_name: "ethnicity" },
        { csv_field_name: mappedField[0].employment, normalized_field_name: "employment" },
      ];

      if (customFields.length > 0) {
        customFields.map((field) => {
          mapped.push({
            csv_field_name: field.mappedField,
            custom_field_name: field.name,
            custom_field_type: field.type,
            groupable: field.groupable,
          });
        });
      }

      options.data = JSON.stringify({
        mapped_fields: mapped,
        append_tags: tags,
        override_optinDate: completeOptinDate ? optinDate : null,
        override_country: selectedCountry,
        zip_to_location: inferFromZip,
        first_to_gender: inferFromName,
        override_source: source,
        uploadedFilePath: filePath,
        add_records: addRecords,
        update_records: updateRecords,
      });

      client
        .request(options)
        .then((response) => {
          setIsLoading(false);
          clearVariables();
          props.onDone();
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const getTags = async () => {
    const options = {
      method: "GET",
      url: `broadcasts/getTags`,
      headers: {
        "content-type": "application/json",
      },
    };

    await client
      .request(options)
      .then((response) => {
        setSuggestionsTag([]);
        response.map((tag, index) => {
          setSuggestionsTag((prev) => [...prev, { id: index, name: tag }]);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getTags();
  }, []);

  useEffect(() => {
    if (mappedField[0].email == "" && activeStep != 0) setFailedSteps([0]);
    else setFailedSteps([]);

    switch (activeStep) {
      case 0:
        setSelectedHeader(mappedField[0].email);
        break;
      case 1:
        setSelectedHeader(mappedField[0].first);
        setSelectedFullname(mappedField[0].fullname);
        break;
      case 2:
        setSelectedHeader(mappedField[0].last);
        break;
      case 3:
        setSelectedHeader(mappedField[0].city);
        break;
      case 4:
        setSelectedHeader(mappedField[0].state);
        break;
      case 5:
        setSelectedHeader(mappedField[0].country);
        break;
      case 6:
        setSelectedHeader(mappedField[0].tags);
        break;
      case 7:
        setSelectedHeader(mappedField[0].optinDate);
        break;
      case 8:
        setSelectedFullname(mappedField[0].age);
        setSelectedHeader(mappedField[0].dateOfBirth);
        break;
      case 9:
        setSelectedHeader(mappedField[0].gender);
        break;
      case 10:
        setSelectedHeader(mappedField[0].phone);
        break;
      case 11:
        setSelectedHeader(mappedField[0].zip);
        break;
      case 12:
        setSelectedHeader(mappedField[0].source);
        break;
      case 13:
        setSelectedHeader(mappedField[0].optinip);
        break;
      case 14:
        setSelectedHeader(mappedField[0].education);
        break;
      case 15:
        setSelectedHeader(mappedField[0].children);
        break;
      case 16:
        setSelectedHeader(mappedField[0].ethnicity);
        break;
      case 17:
        setSelectedHeader(mappedField[0].employment);
        break;
    }
  }, [activeStep]);

  const getStep = (step) => {
    let label = "";
    switch (step) {
      case 0:
        label = <MDTypography>Please select your email field</MDTypography>;
        break;
      case 1:
        label = <MDTypography>Please select your First name field</MDTypography>;
        break;
      case 2:
        label = <MDTypography>Please select your Last name field</MDTypography>;
        break;
      case 3:
        label = <MDTypography>Please select your City name field</MDTypography>;
        break;
      case 4:
        label = <MDTypography>Please select your State field</MDTypography>;
        break;
      case 5:
        label = <MDTypography>Please select your Country field</MDTypography>;
        break;
      case 6:
        label = <MDTypography>Please select your Tags field</MDTypography>;
        break;
      case 7:
        label = <MDTypography>Please select your Optin date field</MDTypography>;
        break;
      case 8:
        label = <MDTypography>Please select your Date of birth field</MDTypography>;
        break;
      case 9:
        label = <MDTypography>Please select your Gender field</MDTypography>;
        break;
      case 10:
        label = <MDTypography>Please select your Phone field</MDTypography>;
        break;
      case 11:
        label = <MDTypography>Please select your ZIP field</MDTypography>;
        break;
      case 12:
        label = <MDTypography>Please select your Source field</MDTypography>;
        break;
      case 13:
        label = <MDTypography>Please select your Optin IP field</MDTypography>;
        break;
      case 14:
        label = <MDTypography>Please select your Education field</MDTypography>;
        break;
      case 15:
        label = <MDTypography>Please select your Children field</MDTypography>;
        break;
      case 16:
        label = <MDTypography>Please select your Ethnicity field</MDTypography>;
        break;
      case 17:
        label = <MDTypography>Please select your Employment field</MDTypography>;
        break;
    }

    let fullName = "";
    if (activeStep === 1 && selectedHeader === "") {
      fullName = (
        <MDBox display="flex">
          <MDTypography>I have a full name field</MDTypography>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={selectedFullname}
              onChange={handleChangeFullName}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">
                <em>I don't have full name field</em>
              </MenuItem>
              {mappedHeaders.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </MDBox>
      );
    }

    let age = "";
    if (activeStep === 8 && selectedHeader === "") {
      age = (
        <MDBox display="flex">
          <MDTypography>I have a age field</MDTypography>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={selectedAge}
              onChange={handleChangeAge}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">
                <em>I don't have age field</em>
              </MenuItem>
              {mappedHeaders.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </MDBox>
      );
    }
   
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <MDBox display="flex">
            {steps.length - 1 == step ? (
              <MSAddCustomFields
                fields={customFields}
                setFields={setCustomFields}
                mappedHeaders={mappedHeaders}
              />
            ) : (
              <Fragment>
                {label}
                <FormControl sx={{ m: 1, minWidth: 300 }}>
                  <Select
                    value={selectedHeader}
                    onChange={handleChangeHeader}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem key={""} value={""}>
                      I don't have this field
                    </MenuItem>
                    {mappedHeaders.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Fragment>
            )}
          </MDBox>
        </Grid>

        {fullName != "" && <Grid item>{fullName} </Grid>}
        {age != "" && <Grid item>{age} </Grid>}
      </Grid>
    );
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const getProcessingValues = () => {
    let processingCountry = "";
    let processingOptindate = "";
    let processingEngagementdate = "";
    let processingZIP = "";
    let processingGender = "";
    let processingSource = "";

    mappingResponse.field_counts.map((field) => {
      if (
        field.normalized_field_name === "country" &&
        (field.total_valid === 0 || field.total_with_values < field.total_valid)
      ) {
        processingCountry = (
          <MDBox style={{ padding: 10 }}>
            <MDTypography>
              We have detected that the country field has empty values. Do you want to complete it
              manually?
            </MDTypography>
            <MDBox pt={1} pb={1} px={2}>
              <Select
                autoFocus
                value={selectedCountry}
                onChange={handleChangeCountry}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="">
                  <em>Keep empty</em>
                </MenuItem>
                {countries.map((country) => (
                  <MenuItem value={country.sortname}>{country.name}</MenuItem>
                ))}
              </Select>
            </MDBox>
          </MDBox>
        );
      }

      if (
        field.normalized_field_name === "source" &&
        (field.total_valid === 0 || field.total_with_values < field.total_valid)
      ) {
        processingSource = (
          <MDBox style={{ padding: 10 }}>
            <MDTypography>
              We have detected that the source field has empty values. Do you want to complete it
              manually?
            </MDTypography>
            <MDBox pt={1} pb={1} px={2}>
              <MDInput
                autoFocus
                style={{ width: "300px" }}
                onChange={(event) => setSource(event.target.value)}
                value={source}
                label="Enter the value for source or keep empty"
              />
            </MDBox>
          </MDBox>
        );
      }

      if (
        field.normalized_field_name === "optinDate" &&
        (field.total_valid === 0 || field.total_with_values < field.total_valid)
      ) {
        processingOptindate = (
          <MDBox style={{ padding: 10 }}>
            <MDTypography>
              We have detected that the optin date field has empty values. Do you want to complete
              it manually?
            </MDTypography>
            <Switch
              checked={completeOptinDate}
              onChange={() => setcompleteOptinDate(!completeOptinDate)}
            />
            {completeOptinDate && (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  autoFocus
                  required
                  maxDate={Moment()}
                  renderInput={(props) => <TextField {...props} />}
                  label="Select the date"
                  value={optinDate}
                  onChange={(newValue) => {
                    setOptinDate(newValue);
                  }}
                />
              </LocalizationProvider>
            )}
          </MDBox>
        );
      }

      if (
        field.normalized_field_name === "city" &&
        mappingResponse.field_counts.find((field) => field.normalized_field_name === "zip")
          .total_valid > 0 &&
        (field.total_valid === 0 || field.total_with_values < field.total_valid)
      ) {
        processingZIP = (
          <MDBox style={{ padding: 10 }}>
            <MDTypography>
              We have detected that the city field has empty values. Do you want to infer it from
              the ZIP code?
            </MDTypography>
            <Switch
              autoFocus
              checked={inferFromZip}
              onChange={() => setInferFromZip(!inferFromZip)}
            />
          </MDBox>
        );
      }

      if (
        field.normalized_field_name === "gender" &&
        mappingResponse.field_counts.find((field) => field.normalized_field_name === "first")
          .total_valid > 0 &&
        (field.total_valid === 0 || field.total_with_values < field.total_valid)
      ) {
        processingGender = (
          <MDBox style={{ padding: 10 }}>
            <MDTypography>
              We have detected that the gender field has empty values. Do you want to infer it from
              the name?
            </MDTypography>
            <Switch
              autoFocus
              checked={inferFromName}
              onChange={() => setInferFromName(!inferFromName)}
            />
          </MDBox>
        );
      }
    });

    return (
      <Card style={{ padding: 10, marginTop: 10 }}>
        {processingCountry != "" && processingCountry}
        {processingSource != "" && processingSource}
        {processingOptindate != "" && processingOptindate}
        {processingEngagementdate != "" && processingEngagementdate}
        {processingZIP != "" && processingZIP}
        {processingGender != "" && processingGender}
        <MDBox style={{ padding: 10 }}>
          <MDTypography>Tag your subscribers with the following keywords</MDTypography>
          <MDBox pt={1} pb={1} px={2}>
            <Autocomplete
              autoFocus
              value={tags}
              onChange={(event, newValue) => {
                setTags(
                  newValue.map((tag) =>
                    tag.indexOf('Create new tag "') === -1 ? tag : tag.substring(16).slice(0, -1)
                  )
                );
              }}
              multiple
              id="tags-filled"
              options={suggestionsTag.map((option) => option.name)}
              freeSolo
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Type to search or add a new tag and press enter"
                />
              )}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option);
                if (inputValue.trim() !== "" && !isExisting) {
                  filtered.push(`Create new tag "${inputValue}"`);
                }
                return filtered;
              }}
            />
          </MDBox>
        </MDBox>
      </Card>
    );
  };

  const renderStepField = (label) => {
    
    switch (label) {
      case "Email":
        return (
          <MDTypography variant="caption2" color="text">
            {mappedField[0].email == "" ? "Not mapped" : "Mapped with: " + mappedField[0].email}
          </MDTypography>
        );
      case "First":
        return (
          <MDTypography variant="caption2" color="text">
            {mappedField[0].first == ""
              ? "Mapped with: " + mappedField[0].fullname
              : mappedField[0].first == ""
              ? "Not mapped"
              : "Mapped with: " + mappedField[0].first}
          </MDTypography>
        );
      case "Last":
        return (
          <MDTypography variant="caption2" color="text">
            {mappedField[0].last == "" ? "Not mapped" : "Mapped with: " + mappedField[0].last}
          </MDTypography>
        );
      case "City":
        return (
          <MDTypography variant="caption2" color="text">
            {mappedField[0].city == "" ? "Not mapped" : "Mapped with: " + mappedField[0].city}
          </MDTypography>
        );
      case "State":
        return (
          <MDTypography variant="caption2" color="text">
            {mappedField[0].state == "" ? "Not mapped" : "Mapped with: " + mappedField[0].state}
          </MDTypography>
        );
      case "Country":
        return (
          <MDTypography variant="caption2" color="text">
            {mappedField[0].country == "" ? "Not mapped" : "Mapped with: " + mappedField[0].country}
          </MDTypography>
        );
      case "Tags":
        return (
          <MDTypography variant="caption2" color="text">
            {mappedField[0].tags == "" ? "Not mapped" : "Mapped with: " + mappedField[0].tags}
          </MDTypography>
        );
      case "Optin date":
        return (
          <MDTypography variant="caption2" color="text">
            {mappedField[0].optinDate == ""
              ? "Not mapped"
              : "Mapped with: " + mappedField[0].optinDate}
          </MDTypography>
        );
      case "Date of birth":
        return (
          <MDTypography variant="caption2" color="text">
            {mappedField[0].dateOfBirth == ""
              ? mappedField[0].age == ""
                ? "Not mapped"
                : "Mapped with: " + mappedField[0].age
              : "Mapped with: " + mappedField[0].dateOfBirth}
          </MDTypography>
        );
      case "Gender":
        return (
          <MDTypography variant="caption2" color="text">
            {mappedField[0].gender == "" ? "Not mapped" : "Mapped with: " + mappedField[0].gender}
          </MDTypography>
        );
      case "Phone":
        return (
          <MDTypography variant="caption2" color="text">
            {mappedField[0].phone == "" ? "Not mapped" : "Mapped with: " + mappedField[0].phone}
          </MDTypography>
        );
      case "Zip":
        return (
          <MDTypography variant="caption2" color="text">
            {mappedField[0].zip == "" ? "Not mapped" : "Mapped with: " + mappedField[0].zip}
          </MDTypography>
        );
      case "Source":
        return (
          <MDTypography variant="caption2" color="text">
            {mappedField[0].source == "" ? "Not mapped" : "Mapped with: " + mappedField[0].source}
          </MDTypography>
        );
      case "Optin IP":
        return (
          <MDTypography variant="caption2" color="text">
            {mappedField[0].optinip == "" ? "Not mapped" : "Mapped with: " + mappedField[0].optinip}
          </MDTypography>
        );
      case "Education":
        return (
          <MDTypography variant="caption2" color="text">
            {mappedField[0].education == ""
              ? "Not mapped"
              : "Mapped with: " + mappedField[0].education}
          </MDTypography>
        );
      case "Children":
        return (
          <MDTypography variant="caption2" color="text">
            {mappedField[0].children == ""
              ? "Not mapped"
              : "Mapped with: " + mappedField[0].children}
          </MDTypography>
        );
      case "Ethnicity":
        return (
          <MDTypography variant="caption2" color="text">
            {mappedField[0].ethnicity == ""
              ? "Not mapped"
              : "Mapped with: " + mappedField[0].ethnicity}
          </MDTypography>
        );
      case "Employment":
        return (
          <MDTypography variant="caption2" color="text">
            {mappedField[0].employment == ""
              ? "Not mapped"
              : "Mapped with: " + mappedField[0].employment}
          </MDTypography>
        );
      case "Custom Fields":
        return (
          <MDTypography variant="caption2" color="text">
            {customFields.length == 0 ? "Not mapped" : customFields.length + " added"}
          </MDTypography>
        );
    }
  };

  return (
    <Dialog
      open={openModal}
      onClose={(e, reason) => clearVariables(reason)}
      fullScreen
      disableEscapeKeyDown
      style={{ marginLeft: margin }}
      sx={{
        //You can copy the code below in your theme
        background: "#F4F0F700",
        "& .MuiPaper-root": {
          background: theme.palette.background.default,
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent", // Try to remove this to see the result
        },
      }}
    >
      <MDBox
        variant="gradient"
        bgColor="primary"
        borderRadius="xs"
        coloredShadow="primary"
        p={2}
        textAlign="center"
      >
        <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
          Add subscribers
        </MDTypography>
      </MDBox>
      <DialogContent>
        <MDBox pb={3} px={2}>
          <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
            <MDBox component="form" role="form" justifyContent="center" alignItems="center">
              <MDBox mb={2} alignItems="center" display="flex" justifyContent="center">
                <MDTypography fontWeight="medium" mt={1}>
                  Please upload your CSV file with your subscribers.
                </MDTypography>
              </MDBox>
              {selectedFile === null && (
                <MDBox alignItems="center" display="flex" justifyContent="center">
                  <InputLabel htmlFor="import-button">
                    <Input
                      id="import-button"
                      inputProps={{
                        accept:
                          ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                      }}
                      onChange={handleFileSelect}
                      style={{ display: "none" }}
                      type="file"
                    />
                    <MDButton variant="gradient" color="info" component="span">
                      Select file
                    </MDButton>
                  </InputLabel>
                </MDBox>
              )}
              {selectedFile != null && (
                <Fragment>
                  <MDBox alignItems="center" display="flex" justifyContent="center">
                    <MDButton
                      onClick={() => uploadFile()}
                      disabled={isSelected && !failUpload && headers != null}
                      variant="gradient"
                      color="info"
                    >
                      Upload
                    </MDButton>
                  </MDBox>
                  <MDBox mb={2} alignItems="center" display="flex" justifyContent="center">
                    <MDTypography>{selectedFile.name}</MDTypography>
                  </MDBox>
                </Fragment>
              )}
            </MDBox>
          </Card>
          {failUpload && (
            <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
              <MDBox mb={2} alignItems="center" display="flex" justifyContent="center">
                <MDTypography color="error" mt={1}>
                  Your CSV file does not seem to have a valid header. We will need the column names
                  in order to make the normalization process easier for you. Make sure you add to
                  your csv file a first line that describes the content for each column. i.e.:
                  name,email,optin-date. Also make sure they match the order of each item in the
                  rest of the file. In our example: john,john@gmail.com,2023-12-31T10:21:03
                </MDTypography>
              </MDBox>
            </Card>
          )}
          {headers != null && (
            <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
              <MDTypography variant="h5">Here are some values from your file</MDTypography>
              <Grid container>
                {Array(fieldRows.length > 5 ? 5 : fieldRows.length)
                  .fill(1)
                  .map((elem, i) => (
                    <MDBox mb={2} xs={12} md={4}>
                      <Card
                        xs={12}
                        md={4}
                        key={i}
                        style={{
                          padding: 10,
                          backgroundColor: "white",
                          marginBottom: 10,
                          margin: 5,
                          wordWrap: "break-word",
                        }}
                      >
                        {headers.map((item, index) => (
                          <MDBox display="flex">
                            <MDTypography variant="body1">
                              {" "}
                              <strong>
                                {" "}
                                {item}:{"  "}
                              </strong>
                            </MDTypography>
                            <MDTypography style={{ wordBreak: "break-all" }}>
                              {" "}
                              {fieldRows[i].field[index]}{" "}
                            </MDTypography>
                          </MDBox>
                        ))}
                      </Card>
                    </MDBox>
                  ))}
              </Grid>
              <MDBox pt={2}>
                <MDTypography variant="h5">Fields mapping</MDTypography>
                <Stepper
                  activeStep={activeStep}
                  style={{ padding: 10 }}
                  orientation="vertical"
                  nonLinear
                >
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                      labelProps.optional = <MDTypography variant="caption">Optional</MDTypography>;
                    }
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    if (isStepFailed(index)) {
                      labelProps.optional = (
                        <MDTypography variant="body" color="error">
                          <WarningIcon />
                          The email field must contain a value
                        </MDTypography>
                      );
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepButton color="inherit" onClick={handleStep(index)} i {...labelProps}>
                          {label} {renderStepField(label)}
                        </StepButton>
                        <StepContent>
                          <MDBox sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                            {getStep(activeStep)}
                          </MDBox>
                          <MDBox sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                            <MDButton
                              disabled={activeStep === 0}
                              onClick={handleBack}
                              variant="outlined"
                              color="secondary"
                              sx={{ mr: 1 }}
                            >
                              Back
                            </MDButton>

                            {isStepOptional(activeStep) && (
                              <MDButton
                                autoFocus
                                onClick={handleSkip}
                                sx={{ mr: 1 }}
                                variant="contained"
                                color="primary"
                              >
                                Skip
                              </MDButton>
                            )}
                            <MDButton
                              onClick={handleNext}
                              variant="contained"
                              color="primary"
                              disabled={activeStep === steps.length - 1}
                            >
                              Next
                            </MDButton>
                          </MDBox>
                        </StepContent>
                      </Step>
                    );
                  })}
                  <MDButton
                    onClick={() => mappedField[0].email != "" && mappedFields()}
                    variant="contained"
                    color="primary"
                    style={{ marginTop: 10, width: "200px" }}
                    disabled={isMapping && mappingResponse !== null}
                  >
                    Finish
                    {isMapping && (
                      <CircularProgress
                        size={24}
                        style={{ marginLeft: 15, position: "relative", top: 2, color: "white" }}
                      />
                    )}
                  </MDButton>
                </Stepper>
              </MDBox>
              {mappingResponse != null && getProcessingValues()}
            </Card>
          )}
          {mappingResponse != null && (
            <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
              <MDBox style={{ padding: 10 }}>
                <MDTypography>Update my current subscribers with the new information</MDTypography>
                <Switch
                  autoFocus
                  checked={updateRecords}
                  onChange={() => setUpdateRecords(!updateRecords)}
                />
                <MDTypography>
                  Add all the subscribers who are not already on my list
                </MDTypography>
                <Switch
                  autoFocus
                  checked={addRecords}
                  onChange={() => setAddRecords(!addRecords)}
                />
              </MDBox>
            </Card>
          )}
        </MDBox>
      </DialogContent>
      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDButton
          onClick={() => clearVariables()}
          disabled={isLoading}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </MDButton>
        <MDButton
          onClick={() => handleSave()}
          disabled={isLoading || !isSelected || mappingResponse == null}
          variant="gradient"
          color="success"
        >
          Save
          {isLoading && (
            <CircularProgress size={24} style={{ marginLeft: 15, position: "relative", top: 4 }} />
          )}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default AddSubscriber;
