/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
 
Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Grid, IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import NearMeDisabledIcon from "@mui/icons-material/NearMeDisabled";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import NearMeIcon from "@mui/icons-material/NearMe";
import DeleteIcon from "@material-ui/icons/Delete";
import MDButton from "components/MDButton";

/* eslint-disable */
function List(props) {
  const renderProfiles = props.catalogs.map(
    ({ cat_name, cat_status, cat_id, total_items, total_active_items }) => (
      <Grid item xs={12} xl={3}>
        <Card sx={{ p: 1, m: 1 }} style={{ backgroundColor: "#FFF" }}>
          {cat_status === 0 ? (
            <Tooltip title={"Active"}>
              <CheckBoxIcon fontSize="small" color="success" />
            </Tooltip>
          ) : (
            <Tooltip title={"Inactive"}>
              <IndeterminateCheckBoxIcon fontSize="small" color="error" />
            </Tooltip>
          )}
          <MDBox display="flex" alignItems="center" py={1} mb={1} flexDirection="column">
            <MDBox
              key={cat_id}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center"
            >
              <MDTypography variant="h4" fontWeight="medium">
                {cat_name}
              </MDTypography>
            </MDBox>
          </MDBox>
          <Card sx={{ p: 1, boxShadow: "none", backgroundColor: "white" }}>
            <MDBox flexDirection="column">
              <MDTypography variant="button" fontWeight="medium">
                Items: {total_items}
              </MDTypography>
            </MDBox>
            <MDBox flexDirection="row">
              <MDTypography variant="button" fontWeight="medium">
                Active items: {total_active_items}
              </MDTypography>
            </MDBox>
          </Card>
          <Grid container>
            <Grid item xs="12">
              <MDBox display="flex" justifyContent="center" alignItems="center">
                <MDButton
                  variant="text"
                  color="info"
                  onClick={() => props.viewItems(cat_id, cat_name)}
                  fullwidth
                >
                  View items
                </MDButton>
              </MDBox>
            </Grid>
            <Grid item xs="12">
              <MDBox display="flex" justifyContent="center" alignItems="center">
                <IconButton
                  onClick={() => {
                    props.onChangeStatus(cat_id, cat_status);
                  }}
                  sx={{ fontWeight: "bold" }}
                  color="primary"
                  aria-label="prompt"
                  size="small"
                >
                  <Tooltip title={cat_status === 1 ? "Activate" : "Deactivate"}>
                    <IconButton>
                      {cat_status === 1 ? (
                        <NearMeIcon fontSize="small" />
                      ) : (
                        <NearMeDisabledIcon fontSize="small" />
                      )}
                    </IconButton>
                  </Tooltip>
                </IconButton>

                <IconButton
                  onClick={() => {
                    props.onEdit(cat_id, cat_name);
                  }}
                  sx={{ fontWeight: "bold" }}
                  color="primary"
                  aria-label="prompt"
                  size="small"
                >
                  <Tooltip title={"Edit catalog "}>
                    <IconButton>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </IconButton>

                <IconButton
                  onClick={() => {
                    props.onEditBlocks(cat_id, cat_name);
                  }}
                  sx={{ fontWeight: "bold" }}
                  color="primary"
                  aria-label="prompt"
                  size="small"
                >
                  <Tooltip title={"Edit HTML blocks "}>
                    <IconButton>
                      <IntegrationInstructionsIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </IconButton>

                <IconButton
                  onClick={() => {
                    props.onDelete(cat_id);
                  }}
                  sx={{ fontWeight: "bold" }}
                  color="primary"
                  aria-label="prompt"
                  size="small"
                >
                  <Tooltip title={"Delete"}>
                    <IconButton>
                      <DeleteIcon color="error" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </IconButton>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    )
  );

  return <Grid container>{renderProfiles}</Grid>;
}

// Setting default props for the ProfilesList
List.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
List.propTypes = {
  catalogs: PropTypes.arrayOf(PropTypes.object).isRequired,
  shadow: PropTypes.bool,
};

export default List;
