/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// import { useState } from "react";
// @mui material components
import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { TableCell } from "@material-ui/core";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import EditIcon from "@mui/icons-material/Edit";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import MDButton from "components/MDButton";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import Moment from "moment";
import client from "ApiClient";
import { Card, CircularProgress, Grid, IconButton, Switch, Tooltip } from "@mui/material";
import ConfirmationAlert from "components/ConfirmationAlert";
import EmailCopy from "layouts/emailCopy/emailCopyTable";
import CustomToolbarSelect from "./customToolbarSelect";
import Edit from "./edit";

/* eslint-disable */
export default function Table(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [series, setSeries] = useState([]);
  const [selectedSerie, setSelectedSerie] = useState([]);
  const [serId, setSerId] = useState();
  const [serName, setSerName] = useState();
  const [openLog, setOpenLog] = useState(false);
  const [articles, setArticles] = useState([]);
  const [onModal, setOnModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  // const handleCloseModal = () => setOpenModal(false);

  const oldRender = TableCell.render;
  TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };

  const options = {
    method: "GET",
    url: "series",
  };

  const getData = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `series`;
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setSeries(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, [props.groupAdded]);

  useEffect(() => {
    if (!openLog) {
      getData();
    }
  }, [openLog]);

  useEffect(() => {
    getArticles();
  }, []);

  const getDateValue = (date) => {
    return Moment(date).format("L");
  };

  const handleEdit = () => {
    handleOpenModal();
  };

  const handleDelete = () => {
    setIsLoading(true);
    options.method = "DELETE";
    options.url = `series`;
    options.data = JSON.stringify(selectedSerie);
    options.headers = {
      "content-type": "application/json",
    };

    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        getData();
        setOpenAlert(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const handleActivate = () => {
    setIsLoading(true);
    options.method = "POST";
    options.url = `series/changeStatus`;
    for (var i = 0; i < selectedSerie.length; i++) {
      selectedSerie[i].ser_status = 0;
    }
    options.data = JSON.stringify(selectedSerie);
    options.headers = {
      "content-type": "application/json",
    };
    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        getData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const handleDeactivate = () => {
    setIsLoading(true);
    options.method = "POST";
    options.url = `series/changeStatus`;

    for (var i = 0; i < selectedSerie.length; i++) {
      selectedSerie[i].ser_status = 1;
    }
    options.data = JSON.stringify(selectedSerie);
    options.headers = {
      "content-type": "application/json",
    };

    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        getData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const getArticles = async () => {
    const options = {
      method: "GET",
      url: `blogArticles/GetArticles`,
      headers: {
        "content-type": "application/json",
      },
    };
    await client
      .request(options)
      .then((response) => {
        let article = [];
        setArticles(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStatus = (value) => {
    switch (value) {
      case 0:
        return (
          <Tooltip title={"Active"}>
            <CheckBoxIcon fontSize="small" color="success" />
          </Tooltip>
        );
      case 1:
        return (
          <Tooltip title={"Inactive"}>
            <IndeterminateCheckBoxIcon fontSize="small" color="error" />
          </Tooltip>
        );
    }
  };

  const getSegmentSelected = (seg) => {
    const segmentProps = JSON.parse(seg);

    const statuses =
      segmentProps.statuses.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Status:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.statuses
              .filter((element) => element.selected)
              .map((s) => s.status)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const educations =
      segmentProps.educations.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Education:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.educations
              .filter((element) => element.selected)
              .map((s) => s.education)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const children =
      segmentProps.children.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Children:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.children
              .filter((element) => element.selected)
              .map((s) => s.children)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const ethnicities =
      segmentProps.ethnicities.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Ethnicities:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.ethnicities
              .filter((element) => element.selected)
              .map((s) => s.ethnicity)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const genders =
      segmentProps.genders.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Genders:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.genders
              .filter((element) => element.selected)
              .map((s) => s.gender)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const age =
      segmentProps.ageRanges.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Age ranges:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.ageRanges
              .filter((element) => element.selected)
              .map((s) => s.age_range)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const employments =
      segmentProps.employments.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Employments:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.employments
              .filter((element) => element.selected)
              .map((s) => s.employment)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const broadcastsEngaged =
      segmentProps.broadcastsEngaged.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Broadcasts engaged:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.broadcastsEngaged
              .filter((element) => element.selected)
              .map((s) => s.name)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const broadcastsNoEngaged =
      segmentProps.broadcastsNoEngaged.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Broadcasts no engaged:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.broadcastsNoEngaged
              .filter((element) => element.selected)
              .map((s) => s.name)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    if (segmentProps.tags == null) segmentProps.tags = [];
    const tags =
      segmentProps.tags.length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Tags:{" "}
          </MDTypography>
          <MDTypography variant="body">{segmentProps.tags.join(", ")}</MDTypography>
        </div>
      ) : (
        ""
      );

    if (
      statuses == "" &&
      educations == "" &&
      children == "" &&
      employments == "" &&
      ethnicities == "" &&
      genders == "" &&
      age == "" &&
      broadcastsEngaged == "" &&
      broadcastsNoEngaged == "" &&
      tags == ""
    )
      return (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            None
          </MDTypography>
        </div>
      );
    else
      return (
        <div>
          {statuses}
          {educations}
          {employments}
          {children}
          {ethnicities}
          {genders}
          {age}
          {broadcastsEngaged}
          {broadcastsNoEngaged}
          {tags}
        </div>
      );
  };

  const columns = [
    {
      label: " ",
      name: "ser_status",
      options: {
        filter: true,
        customBodyRender: (value) => getStatus(value),
      },
    },
    {
      label: "Name",
      name: "ser_name",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Frecuency (days)",
      name: "ser_frequencyDays",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Add new subscribers",
      name: "ser_addNewSubscribers",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
        customBodyRender: (value) => {
          return <Switch checked={value} />;
        },
      },
    },
    {
      label: "Recurrent",
      name: "ser_restart",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
        customBodyRender: (value) => {
          return <Switch checked={value} />;
        },
      },
    },
    {
      label: "Auto sort",
      name: "ser_autoSort",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
        customBodyRender: (value) => {
          return <Switch checked={value} />;
        },
      },
    },
    {
      label: "Segmentation",
      name: "ser_segments",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
        customBodyRender: (value) => getSegmentSelected(value),
      },
    },
    {
      label: "Creation date",
      name: "ser_creationDate",
      options: {
        filter: true,
        customBodyRender: (value) => getDateValue(value),
      },
    },
    {
      label: " ",
      name: "ser_id",
      options: {
        filter: true,
        setCellHeaderProps: () => {
          return { align: "right" };
        },
        setCellProps: () => {
          return { align: "right" };
        },
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div>
              <IconButton
                onClick={() => {
                  setSelectedSerie(series[dataIndex]);
                  setOpenLog(true);
                }}
                sx={{ fontWeight: "bold" }}
                color="primary"
                aria-label="prompt"
              >
                <Tooltip id="button-report" title="Edit">
                  <EditIcon />
                </Tooltip>
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  const customToolbar = () => (
    <CustomToolbarSelect
      onDelete={() => setOpenAlert(true)}
      onActivate={handleActivate}
      onDeactivate={handleDeactivate}
    />
  );

  const tableOptions = {
    textLabels: {
      body: {
        noMatch: "You don't have any series",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "Clear filter",
      },
      filterType: "dropdown",
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "series/s selected",
      },
    },
    print: false,
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: false,
    selectableRowsOnClick: false,
    responsive: "simple",
    filterType: "dropdown",
    selectableRows: "multiple",
    fixedHeader: true,
    fixedSelectColumn: true,
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Search",
    downloadOptions: { filename: "series.csv", separator: "," },
    onRowClick: (rowData, index) => {
      setSerId(series[index.dataIndex].ser_id);
      setSerName(series[index.dataIndex].ser_name);
    },
    onRowSelectionChange: (curRowSelected, allRowsSelected) => {
      let selected = [];
      // emailsSelected.push(acconuntsData[curRowSelected[0].dataIndex]);
      allRowsSelected.map((row) => {
        selected.push(series[row.dataIndex]);
      });
      setSelectedSerie(selected);
    },
    customToolbarSelect: customToolbar,
  };

  return (
    <div>
      <ConfirmationAlert
        openModal={openAlert}
        onClose={() => setOpenAlert(false)}
        onYes={handleDelete}
        yesText="Yes"
        title="Are you sure you want to delete this series? The email associated with it will be deleted."
      />
      <Edit
        openModal={openLog}
        closeModal={() => setOpenLog(false)}
        serie={selectedSerie}
        onDone={() => getData()}
      />
      <MDBox
        mx={2}
        mt={1}
        py={3}
        px={2}
        bgColor="badgeColors.primary"
        borderRadius="xs"
        variant="gradient"
      >
        <Grid container>
          <Grid item xs={12} md={10}>
            <MDTypography variant="h2">
              Series
              {isLoading && (
                <CircularProgress
                  size={30}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
              )}
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={2} style={{ width: "100%", textAlign: "right" }}>
            <MDBox justifyContent="right">
              <Tooltip title="Create a new series">
                <MDButton variant="gradient" color="secondary" onClick={props.onAdd}>
                  Create new
                </MDButton>
              </Tooltip>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox>
        <Grid container p={2}>
          {!onModal && (
            <Grid item xs={12}>
              <div style={{ height: "100%", width: "100%" }}>
                <MUIDataTable data={series} columns={columns} options={tableOptions} />
              </div>
            </Grid>
          )}
          {serId && (
            <Grid item xs={12} pt={2}>
              <div style={{ height: "100%", width: "100%" }}>
                <Card>
                  <EmailCopy
                    serId={serId}
                    serName={serName}
                    onModal={(modal) => setOnModal(modal)}
                  />
                </Card>
              </div>
            </Grid>
          )}
        </Grid>
      </MDBox>
    </div>
  );
}
