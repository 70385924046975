import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useEffect, useState } from "react";
import { newBlocks } from "./blocks/newblocks";
import { updateBlocks } from "./blocks/updateblocks";
import { ExpandHeading } from "./components/expandHeading";
import { ExpandText } from "./components/expandText";
import grapesjs from "grapesjs";
import myNewComponentTypes from "./components/grapejscomponents/index.ts";
import customCodePlugin from "./utils/custom-code-grapejs/index.ts";
import gjsModal from "./utils/grapejsModal/index.ts";
import gjsImageEditor from "./utils/grapeJsImageEditor/index.ts";
import placeholder from "./utils/grapesJSPlaceholder/index.js";
import gjsp from "grapesjs-preset-webpage";
import gjsTooltip from "grapesjs-tooltip";
import { ImageAI } from "./components/imageAI";
import alignment from "./customstyles/alignment";
import { grapeJsEvents } from "./events/grapeJsEvents";
import { sidebarPannel } from "./panels/sidebarpanel";
import tiny_mce from "./tiny_mce";
import { utils } from "./utils";
import grapeJsAccordian from "./utils/grapeJsAccordian";
import grapeJsTabs from "./utils/grapeJsTabs";
import replaceTextViewComponent from "./utils/single-click-tinymce";
import { Card, CardHeader, Dialog } from "@mui/material";
import { DialogContent } from "@mui/material";
import GrapeEditor from "./grapeEditor";
export default function Grape() {
  return (
    <DashboardLayout>
      <GrapeEditor />
    </DashboardLayout>
  );
}
