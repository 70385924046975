/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useRef, useState, React, Fragment, useEffect } from "react";
import theme from "assets/theme";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import client from "ApiClient";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  Switch,
  Tooltip,
} from "@mui/material";
import SimpleReactValidator from "simple-react-validator";
import PublishIcon from "@mui/icons-material/Publish";
import MDButton from "components/MDButton";

const steps = ["Name", "External ID", "Text", "Link", "Image URL", "Expiration Date"];

/* eslint-disable */
function AddCsv(props) {
  const [margin, setMargin] = useState(300);
  const [isLoading, setIsLoading] = useState(false);
  const [isMapping, setIsMapping] = useState(false);
  const [failUpload, setFailUpload] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [filePath, setFilePath] = useState("");
  const [headers, setHeaders] = useState(null);
  const [mappedHeaders, setMappedHeaders] = useState([]);
  const [selectedHeader, setSelectedHeader] = useState("");

  const [updateRecords, setUpdateRecords] = useState(true);
  const [addRecords, setAddRecords] = useState(true);

  const [fieldRows, setFieldRows] = useState([]);
  const { openModal, closeModal } = props;
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(new SimpleReactValidator());

  const [selectedFile, setSelectedFile] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [skippedSteps, setSkippedSteps] = useState([]);
  const [failedSteps, setFailedSteps] = useState([]);

  const [mappingResponse, setMappingResponse] = useState(null);

  let mappedField = useState({
    name: "",
    externalID: "",
    text: "",
    link: "",
    imageURL: "",
    expirationDate: "",
  });

  const clearVariables = () => {
    setFailUpload(false);
    setIsSelected(false);
    setFilePath("");
    setHeaders(null);
    setMappedHeaders([]);
    setSelectedFile(null);
    setSelectedHeader("");
    setFieldRows([]);
    setActiveStep(0);
    setSkipped(new Set());
    setFailedSteps([]);
    setMappingResponse(null);
    mappedField = {
      name: "",
      externalID: "",
      text: "",
      link: "",
      imageURL: "",
      expirationDate: "",
    };
    closeModal();
  };

  const mappedFields = () => {
    setIsMapping(true);
    try {
      const options = {
        method: "POST",
        url: `items/mappedFields`,
        headers: {
          "content-type": "application/json",
        },
      };

      options.data = JSON.stringify({
        mapped_fields: [
          { csv_field_name: mappedField[0].name, normalized_field_name: "name" },
          { csv_field_name: mappedField[0].externalID, normalized_field_name: "externalID" },
          { csv_field_name: mappedField[0].text, normalized_field_name: "text" },
          { csv_field_name: mappedField[0].link, normalized_field_name: "link" },
          { csv_field_name: mappedField[0].imageURL, normalized_field_name: "imageURL" },
          {
            csv_field_name: mappedField[0].expirationDate,
            normalized_field_name: "expirationDate",
          },
        ],
        uploadedFilePath: filePath,
      });

      client
        .request(options)
        .then((response) => {
          setIsMapping(false);
          console.log(response);
          setMappingResponse(response);
        })
        .catch((error) => {
          setIsMapping(false);
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  // let mappedField = [];
  const isStepOptional = (step) => {
    return skippedSteps.includes(step);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const isStepFailed = (step) => {
    return failedSteps.includes(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    // setMappedHeaders(mappedHeaders.filter((item) => !mappedField.includes(item) && item != selectedHeader));
    switch (activeStep) {
      case 0:
        mappedField[0].name = selectedHeader;
        break;
      case 1:
        mappedField[0].externalID = selectedHeader;
        break;
      case 2:
        mappedField[0].text = selectedHeader;
        break;
      case 3:
        mappedField[0].link = selectedHeader;
        break;
      case 4:
        mappedField[0].imageURL = selectedHeader;
        break;
      case 5:
        mappedField[0].expirationDate = selectedHeader;
        break;
    }

    setSelectedHeader("");
    if (activeStep === steps.length - 1) {
      mappedFields();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    switch (activeStep) {
      case 0:
        mappedField[0].name = selectedHeader;
        break;
      case 1:
        mappedField[0].externalID = selectedHeader;
        break;
      case 2:
        mappedField[0].text = selectedHeader;
        break;
      case 3:
        mappedField[0].link = selectedHeader;
        break;
      case 4:
        mappedField[0].imageURL = selectedHeader;
        break;
      case 5:
        mappedField[0].expirationDate = selectedHeader;
        break;
    }

    setSelectedHeader("");
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleChangeHeader = (event) => {
    setSelectedHeader(event.target.value);
  };
  /*
  const handleChangeFullName = (event) => {
    setSelectedFullname(event.target.value);
  };

  const handleChangeAge = (event) => {
    setSelectedAge(event.target.value);
  };

  const handleChangeCountry = (event) => {
    setSelectedCountry(event.target.value);
  };
*/
  const uploadFile = () => {
    if (simpleValidator.current.allValid()) {
      setIsLoading(true);
      try {
        const options = {
          method: "POST",
          url: `items/uploadfile`,
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const formData = new FormData();
        formData.append("file", selectedFile);
        options.data = formData;
        client
          .request(options)
          .then((response) => {
            setIsLoading(false);
            setFilePath(response);
            validateFile(response);
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const validateFile = (file) => {
    setIsLoading(true);
    try {
      const options = {
        method: "POST",
        url: `items/GetCSVFields?uploadedFilePath=` + file,
      };
      client
        .request(options)
        .then((response) => {
          setIsLoading(false);
          if (response !== "") {
            const head = response.headers.filter((item) => item.trim() != "");
            console.log(response);
            setHeaders(head);
            setMappedHeaders(head);
            setFieldRows(response.rows);
          } else setFailUpload(true);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = () => {
    setIsLoading(true);
    try {
      const options = {
        method: "POST",
        url: `items/commit`,
        headers: {
          "content-type": "application/json",
        },
      };

      options.data = JSON.stringify({
        mapped_fields: [
          { csv_field_name: mappedField[0].name, normalized_field_name: "name" },
          { csv_field_name: mappedField[0].externalID, normalized_field_name: "externalID" },
          { csv_field_name: mappedField[0].text, normalized_field_name: "text" },
          { csv_field_name: mappedField[0].link, normalized_field_name: "link" },
          { csv_field_name: mappedField[0].imageURL, normalized_field_name: "imageURL" },
          {
            csv_field_name: mappedField[0].expirationDate,
            normalized_field_name: "expirationDate",
          },
        ],
        uploadedFilePath: filePath,
        item_cat_id: props.catId,
        add_records: addRecords,
        update_records: updateRecords,
      });

      client
        .request(options)
        .then((response) => {
          setIsLoading(false);
          clearVariables();
          // props.onDone();
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  useEffect(() => {
    /*
    if (mappedField[0].email == "" && activeStep != 0) setFailedSteps([0]);
    else setFailedSteps([]);
*/
    switch (activeStep) {
      case 0:
        setSelectedHeader(mappedField[0].name);
        break;
      case 1:
        setSelectedHeader(mappedField[0].externalID);
        break;
      case 2:
        setSelectedHeader(mappedField[0].text);
        break;
      case 3:
        setSelectedHeader(mappedField[0].link);
        break;
      case 4:
        setSelectedHeader(mappedField[0].imageURL);
        break;
      case 5:
        setSelectedHeader(mappedField[0].expirationDate);
        break;
    }
  }, [activeStep]);

  const getStep = (step) => {
    let label = "";
    switch (step) {
      case 0:
        label = <MDTypography>Please select your name field</MDTypography>;
        break;
      case 1:
        label = <MDTypography>Please select your External ID field</MDTypography>;
        break;
      case 2:
        label = <MDTypography>Please select your text field</MDTypography>;
        break;
      case 3:
        label = <MDTypography>Please select your link field</MDTypography>;
        break;
      case 4:
        label = <MDTypography>Please select your image URL field</MDTypography>;
        break;
      case 5:
        label = <MDTypography>Please select your expiration date field</MDTypography>;
        break;
    }

    return (
      <Fragment>
        {label}
        <FormControl sx={{ m: 1, minWidth: 300 }}>
          <Select
            value={selectedHeader}
            onChange={handleChangeHeader}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem key={""} value={""}>
              I don't have this field
            </MenuItem>
            {mappedHeaders.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Fragment>
    );
  };

  return (
    <Dialog
      open={openModal}
      onClose={closeModal}
      style={{ marginLeft: margin }}
      fullScreen
      sx={{
        //You can copy the code below in your theme
        background: theme.palette.background.default,
        "& .MuiPaper-root": {
          background: theme.palette.background.default,
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent", // Try to remove this to see the result
        },
      }}
    >
      <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="xs"
        coloredShadow="info"
        p={2}
        textAlign="center"
      >
        <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
          Add items from csv file
        </MDTypography>
      </MDBox>

      <DialogContent>
        <MDBox  pb={3} px={2}>
          <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
            <MDBox component="form" role="form" justifyContent="center" alignItems="center">
              <MDBox mb={2} alignItems="center" display="flex" justifyContent="center">
                <MDTypography fontWeight="medium" mt={1}>
                  Please upload your CSV file with your items.
                </MDTypography>
              </MDBox>
              <MDBox alignItems="center" display="flex" justifyContent="center">
                <InputLabel htmlFor="import-button">
                  <Input
                    id="import-button"
                    inputProps={{
                      accept:
                        ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                    }}
                    onChange={handleFileSelect}
                    style={{ display: "none" }}
                    type="file"
                  />
                  <Tooltip id="button-add" title="Select a CSV file">
                    <Grid container spacing={2} m={2}>
                      <Grid item mt={1}>
                        <MDTypography>Pick your file</MDTypography>
                      </Grid>
                      <Grid item>
                        <PublishIcon fontSize="large" color="primary" />
                      </Grid>
                    </Grid>
                  </Tooltip>
                </InputLabel>
              </MDBox>
              {selectedFile != null && (
                <Fragment>
                  <MDBox alignItems="center" display="flex" justifyContent="center">
                    <MDButton
                      autoFocus
                      onClick={() => uploadFile()}
                      disabled={!isSelected}
                      variant="gradient"
                      color="info"
                    >
                      Upload
                    </MDButton>
                  </MDBox>
                  <MDBox mb={2} alignItems="center" display="flex" justifyContent="center">
                    <MDTypography>{selectedFile.name}</MDTypography>
                  </MDBox>
                </Fragment>
              )}
            </MDBox>
          </Card>
          {failUpload && (
            <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
              <MDBox mb={2} alignItems="center" display="flex" justifyContent="center">
                <MDTypography color="error" mt={1}>
                  Your CSV file does not seem to have a valid header. We will need the column names
                  in order to make the normalization process easier for you. Make sure you add to
                  your csv file a first line that describes the content for each column. i.e.:
                  name,email,optin-date. Also make sure they match the order of each item in the
                  rest of the file. In our example: john,john@gmail.com,2023-12-31T10:21:03
                </MDTypography>
              </MDBox>
            </Card>
          )}
          {headers != null && (
            <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
              <MDTypography variant="h5">Here are some values from your file</MDTypography>
              <MDBox mb={2} display="flex">
                {Array(fieldRows.length > 5 ? 5 : fieldRows.length)
                  .fill(1)
                  .map((elem, i) => (
                    <Card
                      key={i}
                      style={{
                        padding: 10,
                        backgroundColor: "white",
                        marginBottom: 10,
                        margin: 5,
                        wordWrap: "break-word",
                      }}
                    >
                      {headers.map((item, index) => (
                        <MDBox display="flex">
                          <MDTypography variant="body1">
                            {" "}
                            <strong>
                              {" "}
                              {item}:{"  "}
                            </strong>
                          </MDTypography>
                          <MDTypography> {fieldRows[i].field[index]} </MDTypography>
                        </MDBox>
                      ))}
                    </Card>
                  ))}
              </MDBox>
              <MDBox pt={2}>
                <MDTypography variant="h5">Fields mapping</MDTypography>
                <Stepper activeStep={activeStep} style={{ padding: 10 }}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                      labelProps.optional = <MDTypography variant="caption">Optional</MDTypography>;
                    }
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel i {...labelProps}>
                          {label}
                        </StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>

                <Fragment>
                  <MDBox sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    {getStep(activeStep)}
                  </MDBox>
                  <MDBox sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <MDButton
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                      autoFocus
                      variant="gradient"
                      color="info"
                    >
                      Back
                    </MDButton>

                    {isStepOptional(activeStep) && (
                      <MDButton
                        onClick={handleSkip}
                        sx={{ mr: 1 }}
                        autoFocus
                        variant="gradient"
                        color="info"
                      >
                        Skip
                      </MDButton>
                    )}
                    <MDButton
                      onClick={handleNext}
                      autoFocus
                      variant="gradient"
                      color="info"
                      disabled={isMapping}
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                      {isMapping && (
                        <CircularProgress
                          size={24}
                          style={{ marginLeft: 15, position: "relative", top: 4 }}
                        />
                      )}
                    </MDButton>
                  </MDBox>
                </Fragment>
              </MDBox>
            </Card>
          )}
          {mappingResponse != null && (
            <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
              <MDBox style={{ padding: 10 }}>
                <MDTypography>Update my current subscribers with the new information</MDTypography>
                <Switch checked={updateRecords} onChange={() => setUpdateRecords(!updateRecords)} />
                <MDTypography>
                  Add all the subscribers that I don't have already on my list
                </MDTypography>
                <Switch checked={addRecords} onChange={() => setAddRecords(!addRecords)} />
              </MDBox>
            </Card>
          )}
        </MDBox>
      </DialogContent>
      <DialogActions style={{ color: "white", backgroundColor: "white" }}>      
        <MDButton
          onClick={() => clearVariables()}
          disabled={isLoading}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </MDButton>
        <MDButton
          onClick={() => handleSave()}
          disabled={isLoading || !isSelected || mappingResponse == null}
          variant="gradient"
          color="success"
        >
          Save
          {isLoading && (
            <CircularProgress size={24} style={{ marginLeft: 15, position: "relative", top: 4 }} />
          )}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default AddCsv;
