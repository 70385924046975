import { useContext, useEffect, useRef, useState } from "react";
import theme from "assets/theme";
import EmailEditor from "react-email-editor";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import client from "ApiClient";
import {
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  ListItemText,
  MenuItem,
  NativeSelect,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import SimpleReactValidator from "simple-react-validator";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import MDSnackbar from "components/MDSnackbar";
import Moment from "moment";
import { Checkbox } from "@material-ui/core";
import MSPersonalizationTags from "components/MSPersonalizationTags";
import { useMaterialUIController } from "context";
import { FeatureFlags } from "context/FeatureFlags";
import MSMultipleSubjects from "components/MSMultipleSubjects";
import GrapeEditor from "layouts/grape/grapeEditor";

/* eslint-disable */
function EditEmailCopy(props) {
  const [margin, setMargin] = useState(300);
  const [isLoading, setIsLoading] = useState(false);
  const [showTRacking, setShowTracking] = useState(false);
  const [isTracking, setIsTracking] = useState(false);
  const [includeInSecuence, setIncludeInSecuence] = useState(false);
  const [expire, setExpire] = useState(false);
  const [addArticlesSuggestion, setAddArticlesSuggestion] = useState(false);
  const [token, setToken] = useState(JSON.parse(localStorage.getItem("AuthorizationToken")));
  const [catalogs, setCatalogs] = useState([]);
  const [valueDate, setValueDate] = useState();
  const [subject, setSubject] = useState(null);
  const [envelopFrom, setEnvelopFrom] = useState();
  const [displayFrom, setDisplayFrom] = useState();
  const [contentText, setContentText] = useState();
  const [mimeType, setMimeType] = useState();
  const { openModal, closeModal } = props;
  const [, forceUpdate] = useState();
  const [templateBody, setTemplateBody] = useState();
  const [jsonDesign, setJsonDesign] = useState();
  const [htmlPreview, setHtmlPreview] = useState("");
  const [subjectPreview, setSubjectPreview] = useState("");
  const [arrSenderProfiles, setArrSenderProfiles] = useState([]);
  const [senderProfile, setSenderProfile] = useState(0);
  const [testEmail, setTestEmail] = useState("");
  const [openTestSB, setOpenTestSB] = useState(false);
  const closeTestSB = () => setOpenTestSB(false);
  const [selectedSubject, setSelectedSubject] = useState("");
  const { features } = useContext(FeatureFlags);

  const [htmlBody, setHtmlBody] = useState("");
  const [jsonBody, setJsonBody] = useState("");

  const [controller] = useMaterialUIController();
  const { miniSidenav } = controller;

  useEffect(() => {
    if (miniSidenav) setMargin(0);
    else setMargin(300);
  }, [miniSidenav]);

  const simpleValidator = useRef(
    new SimpleReactValidator({
      validators: {
        sender: {
          // name the rule
          message: "Please select a sender profile",
          rule: (val, params, validator) => {
            return val >= 0;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true, // optional
        },
      },
    })
  );

  const clearVariables = (reason) => {
    if (reason === "backdropClick") return;
    setSubject(null);
    setEnvelopFrom("");
    setDisplayFrom("");
    setValueDate(Moment());
    setContentText("");
    setMimeType(0);
    setTemplateBody("");
    setHtmlPreview("");
    setSubjectPreview("");
    setHtmlBody("");
    setJsonBody("");
    closeModal();
  };

  useEffect(() => {
    if (props.copy) {
      setExpire(false);
      setAddArticlesSuggestion(false);
      setValueDate(props.copy.emc_expireDate);
      setSubject(
        Array.isArray(props.copy.emc_subjects)
          ? props.copy.emc_subjects
          : [props.copy.emc_subjects] ?? ""
      );
      setEnvelopFrom(props.copy.emc_EnvelopFrom ?? "");
      setDisplayFrom(props.copy.emc_displayFrom ?? "");
      setContentText(props.copy.emc_contentText ?? "");
      setMimeType(props.copy.emc_mimeType);
      setTemplateBody(props.copy.emc_contentHTML);
      //setJsonBody(props.copy.emc_json != null ? props.copy.emc_json : "");
      setIsTracking(props.copy.emc_trackExternalLinkClicks);
      setSenderProfile(props.copy.emc_sep_id);
    }
  }, [props.copy]);

  const handleSave = () => {
    if (simpleValidator.current.allValid()) {
      const options = {
        method: "PUT",
        url: `emailCopy`,
        headers: {
          "content-type": "application/json",
        },
      };

      options.data = JSON.stringify([
        {
          emc_id: props.copy.emc_id,
          emc_user_id: props.copy.emc_user_id,
          emc_mimeType: mimeType,
          emc_contentText: contentText,
          emc_displayFrom: displayFrom,
          emc_envelopFrom: envelopFrom,
          emc_subject: subject[0],
          emc_subjects: subject,
          emc_contentHTML: htmlBody,
          emc_json: JSON.stringify(jsonBody),
          emc_status: props.copy.emc_status,
          emc_bla_id: 0,
          emc_creationDate: new Date(),
          emc_type: 0,
          emc_ser_id: props.copy.emc_ser_id,
          emc_expireDate: expire ? valueDate : null,
          emc_addArticlesSuggestion: addArticlesSuggestion,
          emc_order: props.copy.emc_order,
          emc_sep_id: senderProfile,
          emc_trackExternalLinkClicks: isTracking,
        },
      ]);

      client
        .request(options)
        .then((response) => {
          setIsLoading(false);
          //props.onDone();
          clearVariables();
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleChangeSubject = (subject) => {
    setSubject(subject);
    setSelectedSubject(subject[0]);
  };

  const handleMimeType = (event) => {
    setMimeType(event.target.value);
  };

  const handleIncludeInSecuence = () => {
    setIncludeInSecuence(!includeInSecuence);
  };

  const handleExpire = () => {
    setExpire(!expire);
  };
  const handleAddArticlesSuggestion = () => {
    setAddArticlesSuggestion(!addArticlesSuggestion);
  };
  const handleSendTest = (event) => {
    setTestEmail(event.target.value);
  };

  const getSenderProfiles = async () => {
    setIsLoading(true);
    const options = {
      method: "GET",
      url: `senderProfiles`,
      headers: {
        "content-type": "application/json",
      },
    };
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setArrSenderProfiles(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getCatalogs = async () => {
    const options = {
      method: "GET",
      url: `catalogs`,
      headers: {
        "content-type": "application/json",
      },
    };

    await client
      .request(options)
      .then((response) => {
        let catalog = [];
        response
          .filter((cat) => cat.cat_status == 0)
          .map((item) => {
            catalog.push({ label: item.cat_name, value: item.cat_id });
          });
        setCatalogs(catalog);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendTest = () => {
    if (simpleValidator.current.allValid()) {
      setIsLoading(true);
      const options = {
        method: "POST",
        url: `broadcasts/Send`,
        headers: {
          "content-type": "application/json",
        },
      };

      options.data = JSON.stringify({
        bro_id: 0,
        bro_emc_id: 0,
        name: "",
        displayfrom: displayFrom,
        fromaddress: envelopFrom,
        subjects: selectedSubject.split("|"),
        html: htmlBody,
        pauseStandard: false,
        date: Moment(),
        bro_segments: null,
        recipient: testEmail,
        trigger_events: null,
      });
      client
        .request(options)
        .then((response) => {
          setOpenTestSB(true);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      setErrorTitle("Warning");
      setErrorContent("Please make you sure that you has completed all fields");
      setOpenErrorSB(true);
    }
  };

  useEffect(() => {
    getCatalogs();
    getSenderProfiles();
  }, []);

  /*
  useEffect(() => {
    if (arrSenderProfiles.length > 0) setSenderProfile(props.copy.emc_sep_id);
  }, [arrSenderProfiles]);
  */

  useEffect(() => {
    if (senderProfile > 0) {
      const options = {
        method: "GET",
        url: `senderProfiles`,
        headers: {
          "content-type": "application/json",
        },
      };
      options.method = "GET";
      options.url = `senderProfiles/tracking?sep_id=${senderProfile}`;
      client
        .request(options)
        .then((response) => {
          setShowTracking(response);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
  }, [senderProfile]);

  return (
    <Dialog
      style={{ marginLeft: margin }}
      open={openModal}
      onClose={(e, reason) => clearVariables(reason)}
      fullScreen
      sx={{
        //You can copy the code below in your theme
        background: theme.palette.background.default,
        "& .MuiPaper-root": {
          background: theme.palette.background.default,
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent", // Try to remove this to see the result
        },
      }}
    >
      <MDBox
        variant="gradient"
        bgColor="primary"
        borderRadius="xs"
        coloredShadow="primary"
        mx={2}
        mt={-1}
        p={2}
        textAlign="center"
      >
        <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
          Edit email copy "{subject}" for "{props.serName}" serie
        </MDTypography>
      </MDBox>
      <DialogContent>
        <MDBox pb={3} px={2}>
          <MDBox component="form" role="form">
            {false && (
              <>
                <MDBox display="flex" justifyContent="left">
                  <Switch checked={includeInSecuence} onChange={handleIncludeInSecuence} />
                  <MDTypography variant="h6" fontWeight="medium" mt={1}>
                    include in sending sequence
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" justifyContent="left">
                  <Switch checked={addArticlesSuggestion} onChange={handleAddArticlesSuggestion} />
                  <MDTypography variant="h6" fontWeight="medium" mt={1}>
                    add articles suggestions
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" justifyContent="left">
                  <Switch checked={expire} onChange={handleExpire} />
                  <MDTypography variant="h6" fontWeight="medium" mt={1}>
                    Expire
                  </MDTypography>
                  {expire && (
                    <MDBox mt={1} ml={3}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateTimePicker
                          required
                          renderInput={(props) => <TextField {...props} />}
                          label="Select the expiration date "
                          value={valueDate}
                          onChange={(newValue) => {
                            setValueDate(newValue);
                          }}
                        />
                      </LocalizationProvider>
                    </MDBox>
                  )}
                </MDBox>
                <MDBox mb={2}>
                  <NativeSelect id="cmbMime" onChange={handleMimeType} value={mimeType}>
                    <option value="0">HTML</option>
                    <option value="1">Text</option>
                    <option value="2">Text & HTML</option>
                  </NativeSelect>
                </MDBox>
              </>
            )}
            <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
              <MDBox mb={2}>
                <Card xs={12} style={{ padding: 10, backgroundColor: "#FFFFFF", marginBottom: 10 }}>
                  <CardHeader title="Subjects" />
                  <MDBox mb={2} display="flex">
                    {subject && (
                      <MSMultipleSubjects
                        subjects={subject}
                        setSubjects={(value) => handleChangeSubject(value)}
                      />
                    )}
                    <MDBox ml={2}>
                      <MSPersonalizationTags />
                    </MDBox>
                  </MDBox>
                  <MDBox color="red">
                    {simpleValidator.current.message("Subject", subject, "required")}
                  </MDBox>
                </Card>
              </MDBox>
              {arrSenderProfiles.length > 0 && (
                <MDBox mb={2}>
                  <Select
                    placeholder="Select sender profile"
                    value={senderProfile}
                    onChange={(e) => {
                      setSenderProfile(e.target.value);
                    }}
                  >
                    <MenuItem key={0} value={0}>
                      <ListItemText primary={"Select a sender profile"} />
                    </MenuItem>
                    {arrSenderProfiles.map((item) => (
                      <MenuItem key={item.sep_id} value={item.sep_id}>
                        <ListItemText
                          primary={"From:" + item.sep_envelopFrom}
                          secondary={"Reply to:" + item.sep_replyTo}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                  <MDBox color="red">
                    {simpleValidator.current.message("Sender Profile", senderProfile, "sender")}
                  </MDBox>
                </MDBox>
              )}
              {showTRacking && (
                <MDTypography variant="h5" fontWeight="medium" mt={1}>
                  Tracking events{" "}
                  <Checkbox checked={isTracking} onChange={() => setIsTracking(!isTracking)} />
                </MDTypography>
              )}
            </Card>
            {subjectPreview && (
              <Card mt={5} style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
                <Grid item mb={2}>
                  <MDTypography variant="h6" fontWeight="medium" mt={1}>
                    Subject preview
                  </MDTypography>
                  <iframe
                    srcDoc={subjectPreview}
                    height="50"
                    width="100%"
                    allowFullScreen
                    style={{ border: "none" }}
                  />
                </Grid>
              </Card>
            )}

            <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
              <Grid container spacing={2}>
                <Grid item mb={2} xs={10}>
                  <MDTypography variant="h6" fontWeight="medium" mt={1}>
                    HTML content
                  </MDTypography>
                </Grid>
                <Grid item xs={2} style={{ width: "100%", textAlign: "right" }}>
                  <MSPersonalizationTags />
                </Grid>
                <Grid item xs={12}>
                  <GrapeEditor
                    onUpdate={(data, html) => {
                      setHtmlBody(html);
                      setJsonBody(data);
                    }}
                    jsonData={props.copy?.emc_json}
                  />
                </Grid>
              </Grid>
            </Card>
          </MDBox>
          <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
            <Grid>
              <MDInput
                onChange={(event) => handleSendTest(event)}
                label="e-mails to test"
                style={{ marginBottom: 10, width: "100%" }}
              />
              {subject && (
                <MDBox display="flex">
                  <MDTypography>Select a subject line </MDTypography>
                  <Select
                    title="Select a subject line"
                    value={selectedSubject}
                    onChange={(e) => {
                      setSelectedSubject(e.target.value);
                    }}
                    style={{ marginLeft: 10 }}
                  >
                    {subject.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        <ListItemText primary={item} />
                      </MenuItem>
                    ))}
                  </Select>
                  <MDButton
                    style={{ marginLeft: 10 }}
                    variant="gradient"
                    color="success"
                    onClick={sendTest}
                    disabled={isLoading}
                  >
                    Send
                  </MDButton>
                </MDBox>
              )}
            </Grid>
          </Card>
        </MDBox>
      </DialogContent>
      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDButton
          onClick={clearVariables}
          disabled={isLoading}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </MDButton>
        <MDButton onClick={handleSave} disabled={isLoading} variant="gradient" color="success">
          Save
        </MDButton>
        {isLoading && (
          <CircularProgress size={24} style={{ marginLeft: 15, position: "relative", top: 4 }} />
        )}
      </DialogActions>

      <MDSnackbar
        color="success"
        icon="success"
        title="Success"
        content="Your test email has been sent successfully"
        dateTime={""}
        open={openTestSB}
        onClose={closeTestSB}
        close={closeTestSB}
        bgWhite
      />
    </Dialog>
  );
}

export default EditEmailCopy;
