import { useEffect, useState } from "react";
import { ResponsiveIframeViewer, ViewportSize } from "react-responsive-iframe-viewer";

export default function Preview(props) {
  const [htmlSource, setHtmlSource] = useState("");
  const { id } = props;
  useEffect(() => {
    if (id) {
      setHtmlSource(
        `${
          process.env.REACT_APP_API_BASE_URL
        }emailCopy/preview?drp_id=${id}&user_id=${localStorage.getItem("userId")}`
      );
    }
  }, [id]);

  return (
    <center>      
      <ResponsiveIframeViewer
        src={htmlSource}
        title="Preview"
        size={ViewportSize.desktop}
        width="100%"
        height="100%"
      />
    </center>
  );
}
