import { all } from "axios";
import { C, Test } from "ts-toolbelt";

export function sidebarPannel({ editor, settingSidebarOpen, setSettingSidebarOpen }) {
  const panel = editor.Panels;
  let sectionPanel = null;
  let widgetPannel = null;
  let advanceV2Panel = null;

  editor.Commands.add("section", {
    run: function (editor) {
      const panelTitle = document.querySelector(".panel-title");

      if (panelTitle) {
        panelTitle.textContent = "Sections"; // Default title
      }
      function showBar() {
        const editorElementMain = document.querySelector(".gjs-editor");

        if (editorElementMain) {
          editorElementMain.classList.remove("short-sidebar");
          setTimeout(() => window.dispatchEvent(new Event("resize")), 400);
        } else {
          const editorElementIframe = editor.Canvas.getDocument().querySelector(".gjs-editor");

          if (editorElementIframe) {
            editorElementIframe.classList.remove("short-sidebar");
            setTimeout(() => window.dispatchEvent(new Event("resize")), 400);
          }
        }
        if (editor.Commands.isActive("section")) {
          editor.stopCommand("section");
        }
      }

      function renderCustomBlocks() {
        sectionPanel.innerHTML = "";

        var allBlocks = [];
        var allowedIds = [
          "grid-items",
          "sect100",
          "sect50",
          "sect30",
          "sect37",
          "column1",
          "column2",
          "column3",
          "column3-7",
        ];

        editor.BlockManager.getAll().forEach(function (block) {
          if (block && allowedIds.includes(block.id)) {
            delete block.attributes?.category;

            allBlocks.push(block);
          }
        });

        const block1El = editor.BlockManager.render(allBlocks);
        sectionPanel.appendChild(block1El);
      }

      if (sectionPanel == null) {
        const editMenuDiv = document.createElement("div");
        sectionPanel = editMenuDiv;
        const panels = panel.getPanel("views-container");

        panels.set("appendContent", editMenuDiv).trigger("change:appendContent");
      }

      renderCustomBlocks();
      showBar();
    },
    stop: function (editor) {
      if (sectionPanel != null) {
        sectionPanel.innerHTML = "";
      }

      const panels = editor.Panels.getPanel("views-container");
      if (panels) {
        panels.set("appendContent", "");
      }
    },
  });

  panel.addButton("views", [
    {
      id: "section",
      className: "fa fa-server",
      command: "section",
      active: 1,
    },
  ]);

  panel.addButton("views", [
    {
      id: "blocks",
      className: "fa fa-th-large block-btn",
      command: {
        label: "Custom Block",
        category: "Custom Category",
        attributes: {
          class: "gjs-fonts gjs-f-b1",
        },
        run: function (editor) {
          const panelTitle = document.querySelector(".panel-title");

          if (panelTitle) {
            panelTitle.textContent = "Widgets";
          }

         // console.log("panelTitle", panelTitle);

          function showBar() {
            const editorElementMain = document.querySelector(".gjs-editor");

            if (editorElementMain) {
              editorElementMain.classList.remove("short-sidebar");
              setTimeout(() => window.dispatchEvent(new Event("resize")), 400);
            } else {
              const editorElementIframe = editor.Canvas.getDocument().querySelector(".gjs-editor");

              if (editorElementIframe) {
                editorElementIframe.classList.remove("short-sidebar");
                setTimeout(() => window.dispatchEvent(new Event("resize")), 400);
              }
            }
            if (editor.Commands.isActive("section")) {
              editor.stopCommand("section");
            }
          }
          function renderCustomBlocks() {
            widgetPannel.innerHTML = "";

            var allBlocks = [];
            //var basic = ["TEXT", "text", "image", "link", "link-block"];
            //var extra = ["DynamicContent", "CatalogItems"];
            var notAllowedIds = [
              "column1",
              "column2",
              "column3",
              "column3-7",
              "sect50",
              "CallToAction-Item-4",
              "CallToAction-Item-5",
              "Grid-Item",
              "List-Item",
              "sect100",
              "sect50",
              "sect30",
              "sect37",
              "button",
              "divider",
              "grid-items",
              "list-items",
              "text-sect",
              "video",
              "custom-modal",
              "tabs",
            ];

            editor.BlockManager.getAll().forEach(function (block) {
              if (!notAllowedIds.includes(block.id) && block.get("category") != "") {
                allBlocks.push(block);
              }
            });
            const block1El = editor.BlockManager.render(allBlocks);
            widgetPannel.appendChild(block1El);
          }

          if (widgetPannel == null) {
            const editMenuDiv = document.createElement("div");
            widgetPannel = editMenuDiv;
            const panels = panel.getPanel("views-container");
            panels.set("appendContent", editMenuDiv).trigger("change:appendContent");
          }

          renderCustomBlocks();
          showBar();
        },
        stop: function () {
          if (widgetPannel != null) {
            widgetPannel.innerHTML = "";
            const panels = panel.getPanel("views-container");

            panels.set("appendContent", "");
          }
        },
      },
    },
  ]);
  /*
  panel.addButton("views", [
    {
      id: "advance",
      className: "fa fa-sliders",
      command: {
        run: async (editor, sender) => {
          setSettingSidebarOpen(true);
        },
        stop: async (editor, sender) => {
          setSettingSidebarOpen(false);
        },
      },
    },
  ]);
*/
  // Adding the new Advance v2 panel
  /*
  panel.addButton("views", [
    {
      id: "advance-v2",
      className: "fa fa-film",
      command: {
        run: function (editor) {
          const panelTitle = document.querySelector(".panel-title");

          if (panelTitle) {
            panelTitle.textContent = "DFY Sections";
          }

          function showBar() {
            const editorElementMain = document.querySelector(".gjs-editor");

            if (editorElementMain) {
              editorElementMain.classList.remove("short-sidebar");
              setTimeout(() => window.dispatchEvent(new Event("resize")), 400);
            } else {
              const editorElementIframe = editor.Canvas.getDocument().querySelector(".gjs-editor");

              if (editorElementIframe) {
                editorElementIframe.classList.remove("short-sidebar");
                setTimeout(() => window.dispatchEvent(new Event("resize")), 400);
              }
            }
            if (editor.Commands.isActive("advance-v2")) {
              editor.stopCommand("advance-v2");
            }
          }

          function renderAdvanceV2Content(selectedCategory) {
            advanceV2Panel.innerHTML = `
              <select id="block-filter">
                <option value="">All Sections</option>
                <option value="About">About</option>
                <option value="CallToAction">Call To Action</option>
                <option value="Features">Features</option>
                <option value="ContactUs">Contact Us</option>
                <option value="Testimonials">Testimonials</option>
                <option value="FAQs">FAQs</option>

              </select>
              <div class="block-list"></div>`;
            const blockListContainer = advanceV2Panel.querySelector(".block-list");

            function filterBlocks(category = "") {
              blockListContainer.innerHTML = "";

              const allowedBlocks = {
                About: ["About-1", "About-2", "About-3", "About-4", "About-5", "About-6"],
                CallToAction: [
                  "CallToAction-Item-1",
                  "CallToAction-Item-2",
                  "CallToAction-Item-3",
                  "CallToAction-Item-4",
                  "CallToAction-Item-5",
                ],
                Features: [
                  "Features-Item-1",
                  "Features-Item-2",
                  "Features-Item-3",
                  "Features-Item-4",
                ],
                ContactUs: [
                  "ContactUs-Item-1",
                  "ContactUs-Item-2",
                  "ContactUs-Item-3",
                  "ContactUs-Item-4",
                  "ContactUs-Item-5",
                  "ContactUs-Item-6",
                  "ContactUs-Item-7",
                  "ContactUs-Item-8",
                ],

                Testimonials: [
                  "Testimonials-0",
                  "Testimonials-1",
                  "Testimonials-2",
                  "Testimonials-4",
                  "Testimonials-3",
                  "Testimonials-8",
                  "Testimonials-9",
                  "Testimonials-10",
                  "Testimonials-12",
                  "Testimonials-11",
                ],
                FAQs: ["Faq-1", "Faq-2", "Faq-3", "Faq-4"],
              };

              const allBlocks = allowedBlocks.About.concat(
                allowedBlocks.CallToAction,
                allowedBlocks.Features,
                allowedBlocks.ContactUs,
                allowedBlocks.Testimonials,
                allowedBlocks.FAQs
              );

              const selectedBlocks = allowedBlocks[category] || allBlocks;
              const filteredBlocks = editor.BlockManager.getAll().filter((block) => {
                return selectedBlocks.includes(block.id);
              });
              const renderedBlocks = editor.BlockManager.render(filteredBlocks);
              blockListContainer.appendChild(renderedBlocks);
            }

            const blockFilter = advanceV2Panel.querySelector("#block-filter");
            blockFilter.addEventListener("change", function () {
              const selectedCategory = this.value;
              filterBlocks(selectedCategory);
            });

            filterBlocks(); // Initially render all blocks

            showBar();
          }

          if (advanceV2Panel == null) {
            const editMenuDiv = document.createElement("div");
            editMenuDiv.classList.add("advance-custom-templates");
            advanceV2Panel = editMenuDiv;
            const panels = panel.getPanel("views-container");
            panels.set("appendContent", editMenuDiv).trigger("change:appendContent");
          }

          renderAdvanceV2Content(); // Initially render all blocks
        },
        stop: function () {
          if (advanceV2Panel != null) {
            advanceV2Panel.innerHTML = "";
            const panels = panel.getPanel("views-container");
            panels.set("appendContent", "");
          }
        },
      },
    },
  ]);
  */
}
