import { useEffect, useRef, useState } from "react";
import theme from "assets/theme";
// react-router-dom components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import SimpleReactValidator from "simple-react-validator";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import client from "ApiClient";
import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Switch,
} from "@mui/material";

import MDButton from "components/MDButton";
import Segments from "components/Segments";
import Triggers from "components/triggers";
import { Chip, Icon, TextField, Tooltip } from "@material-ui/core";
import { useMaterialUIController } from "context";

/* eslint-disable */
function Add(props) {
  const [margin, setMargin] = useState(300);
  const [isLoading, setIsLoading] = useState(false);
  const [restart, setRestart] = useState(false);
  const [autoSort, setAutoSort] = useState(false);
  const [addNewSubscribers, setAddNewSubscribers] = useState(false);
  const [serName, setName] = useState("");
  const [frequency, setFrequency] = useState(1);
  const { openModal, closeModal } = props;
  const [segments, setSegments] = useState(null);
  const [triggers, setTriggers] = useState(null);
  const [suppressions, setSuppressions] = useState();
  const [suppressionsSelected, setSuppressionsSelected] = useState([]);

  const [maxRestarts, setMaxRestarts] = useState(0);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  const [controller] = useMaterialUIController();
  const { miniSidenav } = controller;

  useEffect(() => {
    if (miniSidenav) setMargin(0);
    else setMargin(300);
  }, [miniSidenav]);

  document.addEventListener("focusin", (e) => {
    if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
      e.stopImmediatePropagation();
    }
  });

  const clearVariables = (reason) => {
    if (reason === "backdropClick") return;
    setName("");
    setAutoSort(false);
    setFrequency(1);
    setRestart(false);
    setAddNewSubscribers(false);
    setSuppressionsSelected([]);
    simpleValidator.current.hideMessages();
    closeModal();
  };

  const handleSave = () => {
    if (simpleValidator.current.allValid()) {
      setIsLoading(true);
      const options = {
        method: "POST",
        url: `series`,
        headers: {
          "content-type": "application/json",
        },
      };

      options.data = JSON.stringify({
        ser_id: 0,
        ser_user_id: 0,
        ser_name: serName,
        ser_segments: JSON.stringify(segments),
        ser_restart: restart,
        ser_autoSort: autoSort,
        ser_frequencyDays: frequency,
        ser_creationDate: new Date(),
        ser_status: 0,
        ser_addNewSubscribers: addNewSubscribers,
        ser_maxRestarts: restart ? maxRestarts : 0,
        ser_spr_ids: suppressions
          .filter((s) => suppressionsSelected.includes(s.spr_name))
          .map((a) => a.spr_id)
          .join(","),
        trigger_events: triggers,
      });

      client
        .request(options)
        .then((response) => {
          setIsLoading(false);
          props.onDone();
          clearVariables();
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } else {
      setIsLoading(false);
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const getSuppressions = async () => {
    const options = {
      method: "GET",
      url: `suppression`,
      headers: {
        "content-type": "application/json",
      },
    };

    await client
      .request(options)
      .then((response) => {
        setSuppressions(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getSuppressions();
  }, []);

  const onChangeName = (event) => {
    setName(event.target.value);
  };

  const onChangeSegment = (value) => {
    setSegments(value);
  };
  const onChangeTriggers = (value) => {
    setTriggers(value);
  };

  return (
    <Dialog
      open={openModal}
      onClose={(e, reason) => clearVariables(reason)}
      style={{ marginLeft: margin }}
      fullScreen
      sx={{
        //You can copy the code below in your theme
        background: theme.palette.background.default,
        "& .MuiPaper-root": {
          background: theme.palette.background.default,
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent", // Try to remove this to see the result
        },
      }}
    >
      <MDBox
        variant="gradient"
        bgColor="primary"
        borderRadius="xs"
        coloredShadow="primary"
        mx={2}
        mt={-1}
        p={2}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
          Create a new Serie
        </MDTypography>
      </MDBox>
      <DialogContent>
        <MDBox pt={4} pb={3} px={2}>
          <MDBox component="form" role="form">
            <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Serie Name"
                  fullWidth
                  value={serName}
                  onChange={(event) => onChangeName(event)}
                  required
                />
                <MDBox color="red">
                  {simpleValidator.current.message("series name", serName, "required")}
                </MDBox>
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="number"
                  label="Sent frequency in days"
                  value={frequency}
                  onChange={(event) => setFrequency(event.target.value)}
                  required
                />
              </MDBox>

              <MDBox display="flex" justifyContent="left">
                <Switch
                  checked={addNewSubscribers}
                  onChange={() => setAddNewSubscribers(!addNewSubscribers)}
                />
                <MDTypography variant="h6" fontWeight="medium" mt={1}>
                  Add new subscribers to this series
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="left">
                <Switch checked={autoSort} onChange={() => setAutoSort(!autoSort)} />
                <MDTypography variant="h6" fontWeight="medium" mt={1}>
                  Auto sort sequence
                </MDTypography>
              </MDBox>

              <MDBox display="flex" justifyContent="left">
                <Switch checked={restart} onChange={() => setRestart(!restart)} />
                <MDTypography variant="h6" fontWeight="medium" mt={1} mr={2}>
                  Once all email copies of this series get sent, restart
                </MDTypography>
                <MDInput
                  label="max restarts"
                  disabled={!restart}
                  style={{ width: 100 }}
                  type="number"
                  value={maxRestarts}
                  onChange={(event) => setMaxRestarts(event.target.value)}
                />
                <MDTypography variant="h6" fontWeight="medium" mt={1} ml={2}>
                  times (set to zero 0 to restart indefinitely)
                </MDTypography>
              </MDBox>
              {suppressions && suppressions.length > 0 && (
                <MDBox mb={2} display="flex" justifyContent="left" textAlign="center">
                  <MDTypography variant="h5" fontWeight="medium" mt={3}>
                    Select the suppressions lists to use
                  </MDTypography>
                  <Autocomplete
                    style={{ width: "50%", marginTop: 10, marginLeft: 5 }}
                    value={suppressionsSelected}
                    onChange={(event, newValue) => {
                      setSuppressionsSelected(newValue);
                    }}
                    id="tags-filled"
                    multiple
                    options={suppressions.map((option) => option.spr_name)}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                      ))
                    }
                    renderInput={(params) => <TextField {...params} placeholder="Type to search" />}
                  />

                  <Tooltip title="Suppression lists">
                    <Icon style={{ marginTop: 25, marginLeft: 5 }}>info</Icon>
                  </Tooltip>
                </MDBox>
              )}

              <MDBox mb={2}>
                <MDTypography variant="h5" fontWeight="medium" mt={1}>
                  Select the segments to send the series{" "}
                  {" (or leave everything unchecked to send to your entire subscribers list)"}
                </MDTypography>
              </MDBox>
              <MDBox mb={2}>
                <Segments
                  changeSegments={(value) => onChangeSegment(value)}
                  filterSegments={null}
                  showRecipientsCount={true}
                  segments={null}
                  load={true}
                />
              </MDBox>
              <MDBox mb={2}>
                <Triggers
                  changeTriggers={(value) => onChangeTriggers(value)}
                  filterSegments={segments}
                  triggers={null}
                />
              </MDBox>
            </Card>
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDButton
          onClick={clearVariables}
          disabled={isLoading}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </MDButton>
        <MDButton onClick={handleSave} disabled={isLoading} variant="gradient" color="success">
          Save
        </MDButton>
        {isLoading && (
          <CircularProgress size={24} style={{ marginLeft: 15, position: "relative", top: 4 }} />
        )}
      </DialogActions>
    </Dialog>
  );
}
export default Add;
