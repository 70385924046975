export function newBlocks(editor) {
  /*
  editor.DomComponents.addType("dynamic-content", {
    isComponent: (el) => el.tagName === "P" && el.classList.contains("dynamic-content"),
    model: {
      defaults: {
        traits: [
          {
            type: "text", // If you don't specify the type, the `text` is the default one
            name: "url", // Required and available for all traits
            label: "URl:", // The label you will see near the input
            default: "https://www.google.com", // Default value to set on the input
            // label: false, // If you set label to `false`, the label column will be removed
            placeholder: "Write a valid URL", // Placeholder to show inside the input
            changeProp: 1,
          },
        ],
        attributes: { type: "text", required: true },
      },
      style: {
        width: "100%",
        size: "10",
        textAlign: "center",
      },
      init() {
        this.set("url", "https://www.google.com");
        this.on("change:url", this.updateContent);
      },
      updateContent() {
        //  console.log("updateContent", this.get("url"));style="text-align:center"
        this.components(
          `<p class="dynamic-content" style="line-height: 140%;">{dynamic-content:${this.get(
            "url"
          )}}</p>`
        );
      },
    },
  });

  editor.BlockManager.add("DynamicContent", {
    tagName: "dynamic-content",
    select: true,
    category: process.env.REACT_APP_SITE_TITLE + " AI",
    label: "Dynamic Content",
    view: ' <div class="gjs-block-forms" data-gjs-type="DynamicContent" data-gjs-title="Dynamic Content"></div>',
    media: `
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#2EA3F2"><path d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q83 0 155.5 31.5t127 86q54.5 54.5 86 127T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Zm0-82q26-36 45-75t31-83H404q12 44 31 83t45 75Zm-104-16q-18-33-31.5-68.5T322-320H204q29 50 72.5 87t99.5 55Zm208 0q56-18 99.5-55t72.5-87H638q-9 38-22.5 73.5T584-178ZM170-400h136q-3-20-4.5-39.5T300-480q0-21 1.5-40.5T306-560H170q-5 20-7.5 39.5T160-480q0 21 2.5 40.5T170-400Zm216 0h188q3-20 4.5-39.5T580-480q0-21-1.5-40.5T574-560H386q-3 20-4.5 39.5T380-480q0 21 1.5 40.5T386-400Zm268 0h136q5-20 7.5-39.5T800-480q0-21-2.5-40.5T790-560H654q3 20 4.5 39.5T660-480q0 21-1.5 40.5T654-400Zm-16-240h118q-29-50-72.5-87T584-782q18 33 31.5 68.5T638-640Zm-234 0h152q-12-44-31-83t-45-75q-26 36-45 75t-31 83Zm-200 0h118q9-38 22.5-73.5T376-782q-56 18-99.5 55T204-640Z"/></svg>`,
    content: `<p class="dynamic-content" style="line-height: 140%;">{dynamic-content:https://www.google.com}</p>`,
  });

  */
  editor.BlockManager.add("Button", {
    label: "Button",
    category: "Basic",
    media: `<svg xmlns="http://www.w3.org/2000/svg" width="27.633" height="24.151" viewBox="0 0 27.633 24.151">
    <g id="icon-button" transform="translate(789.271 -46.353)">
    <g id="Group_22571" data-name="Group 22571" transform="translate(-789.271 46.353)">
    <path id="Path_45394" data-name="Path 45394" d="M-764.2,46.353h-22.514a2.562,2.562,0,0,0-2.559,2.562V59.678a2.561,2.561,0,0,0,2.559,2.56h5.845a1.175,1.175,0,0,0,1.174-1.176,1.175,1.175,0,0,0-1.174-1.176h-5.845a.208.208,0,0,1-.207-.209V48.915a.208.208,0,0,1,.207-.209H-764.2a.211.211,0,0,1,.21.209V59.678a.211.211,0,0,1-.21.209h-1.236a1.176,1.176,0,0,0-1.176,1.176,1.176,1.176,0,0,0,1.176,1.176h1.236a2.562,2.562,0,0,0,2.56-2.56V48.915A2.564,2.564,0,0,0-764.2,46.353Z" transform="translate(789.271 -46.353)" fill="#2980b9"/>
    <path id="Path_45395" data-name="Path 45395" d="M-778.878,53.79a.493.493,0,0,0-.841.349V64.775l2.232-2.537,2.479,5.472,2.521-1.141-2.42-5.342h3.466Z" transform="translate(792.931 -43.559)" fill="#2980b9"/>
    <path id="Path_45396" data-name="Path 45396" d="M-778.217,53.039a.946.946,0,0,0,.664-.271l1.138-1.109a.949.949,0,0,0,.018-1.344.949.949,0,0,0-1.344-.017l-1.138,1.109a.949.949,0,0,0-.018,1.344A.951.951,0,0,0-778.217,53.039Z" transform="translate(793.142 -44.945)" fill="#2980b9"/>
    <path id="Path_45397" data-name="Path 45397" d="M-781.624,53.12l-1.331.869a.95.95,0,0,0-.277,1.317.95.95,0,0,0,.8.43.945.945,0,0,0,.519-.155l1.331-.87a.949.949,0,0,0,.275-1.315A.947.947,0,0,0-781.624,53.12Z" transform="translate(791.526 -43.82)" fill="#2980b9"/>
    <path id="Path_45398" data-name="Path 45398" d="M-780.837,50.036a.952.952,0,0,0-1.338-.141.951.951,0,0,0-.14,1.337l1.173,1.451a.949.949,0,0,0,.74.354.949.949,0,0,0,.6-.212.951.951,0,0,0,.141-1.337Z" transform="translate(791.855 -45.077)" fill="#2980b9"/>
    </g>
    </g>
    </svg>`,
    content: {
      type: "link",
      attributes: {
        "data-custom-id-new": "my-custom-button", // Ensure this line is correct
      },

      style: {
        width: "auto", // Changed width to auto
        display: "block",
        "justify-content": "center",
        "align-items": "center",
        "background-color": "#2980b9",
        color: "#fff",
        padding: "10px 20px",
        "text-align": "center",
        "font-size": "16px",
        cursor: "pointer",
        border: "none",
        outline: "none",
        transition: "background-color 0.3s",
        "max-width": "1140px",
        margin: "12px auto",
      },
      components: `<span class="button-text">Button</span>`,
    },
    // activate: true, // Ensures the button's properties panel is opened upon addition
    // select: true, // Automatically select this component once it is dropped in
  });
  editor.BlockManager.add("TEXT", {
    id: "heading",
    label: "Headline",
    category: "Basic",
    media: `<svg xmlns="http://www.w3.org/2000/svg" width="19.25" height="19.25"><path d="M.781.03A1.186 1.186 0 0 0 .22.4c-.321.421-.287.981.083 1.351.278.278.384.302 1.318.302h.785v15.144h-.785c-.934 0-1.04.024-1.318.302-.502.502-.355 1.336.291 1.656l.16.079h5.358l.183-.09a1.06 1.06 0 0 0 .509-.563c.089-.238.075-.593-.033-.807a1.145 1.145 0 0 0-.467-.476c-.16-.085-.16-.085-1.002-.095l-.842-.01v-6.541h10.33v6.541l-.842.01c-.812.009-.847.012-.986.085a1.007 1.007 0 0 0-.57.871.864.864 0 0 0 .054.422c.091.24.282.452.509.563l.183.09h5.358l.16-.079c.646-.32.793-1.154.291-1.656-.278-.278-.384-.302-1.318-.302h-.785V2.053h.785c.71 0 .801-.006.959-.065.818-.306.889-1.432.117-1.857l-.209-.115h-5.358l-.183.09c-.631.31-.768 1.182-.261 1.663.068.065.188.151.268.193.139.073.174.076.986.085l.842.01v6.541H4.46V2.057l.842-.01c.842-.01.842-.01 1.002-.095a.996.996 0 0 0 .554-.861A.865.865 0 0 0 6.8.66a1.105 1.105 0 0 0-.528-.565l-.16-.079L3.497.011C2.059.008.837.017.781.03" fill="#2980b9" fill-rule="evenodd"/></svg>`,
    // attributes: { class: 'gjs-fonts gjs-f-h1p' },

    content: {
      type: "text",
      content: "Insert Headline here",
      style: {
        "font-weight": "700",
        "font-size": "48px !important",
        "max-width": "1140px",
        margin: "0 auto",
      },
      activeOnRender: true,
    },
  });

  editor.BlockManager.add("link_block", {
    label: "Link Block",
    category: "Basic",

    media: `<svg viewBox="0 0 24 24">
        <path fill="currentColor" d="M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z"></path>
      </svg>`,
    content: {
      type: "link",
      editable: false,
      droppable: true,
      style: {
        "min-height": "50px",
        "min-width": "50px",
        padding: "5px",
        "max-width": "1140px",
        margin: "0 auto",
        display: "block",
      },
    },
  });

  editor.BlockManager.add("spacer", {
    label: "Spacer",
    category: "Basic",
    content: {
      type: "spacer",
      components: '<div style="height:50px;"></div>',
    },
    attributes: {
      class: "fa fa-arrows-v",
    },
  });

  editor.DomComponents.addType("spacer", {
    isComponent: (el) => {
      if (el.tagName === "DIV" && el.style.height) {
        return { type: "spacer" };
      }
    },
    model: {
      defaults: {
        droppable: false, // Prevent dropping other components inside the spacer

        resizable: {
          tl: 0, // Top left
          tc: 1, // Top center (height)
          tr: 0, // Top right
          cl: 0, // Center left
          cr: 0, // Center right
          bl: 0, // Bottom left
          bc: 1, // Bottom center (height)
          br: 0, // Bottom right
        },
        traits: [
          {
            type: "number",
            label: "Height",
            name: "height",
            changeProp: 1,
            min: 10, // Minimum height
            max: 500, // Maximum height
          },
        ],
      },
      init() {
        this.on("change:height", this.updateHeight);
      },
      updateHeight() {
        this.components(`<div style="height:${this.get("height")}px;"></div>`);
      },
    },
  });
  //SECTIONS

  editor.BlockManager.add("Grid-Item", {
    id: "grid-items",
    label: "Grid Items",
    category: "Basic",
    attributes: { class: "fa fa-th" },
    content: `<div>
<table class="grid-item-row" style="box-sizing: border-box; margin: 0 auto 10px auto; padding: 5px 0; width: 100%;" width="100%">
<tbody style="box-sizing: border-box;">
<tr style="box-sizing: border-box;">
<td class="grid-item-cell2-l" style="box-sizing: border-box; vertical-align: top; padding-right: 10px; width: 50%;" width="50%" valign="top">
<table class="grid-item-card" style="box-sizing: border-box; width: 100%; padding: 5px 0 5px 0; margin-bottom: 10px;" width="100%">
<tbody style="box-sizing: border-box;">
    <tr style="box-sizing: border-box;">
        <td class="grid-item-card-cell" style="box-sizing: border-box; background-color: rgb(255, 255, 255); overflow: hidden; border-radius: 3px; text-align: center; padding: 0;" bgcolor="rgb(255, 255, 255)" align="center">
            <img src="https://via.placeholder.com/100" alt="Image" class="grid-item-image" style="box-sizing: border-box; line-height: 150px; font-size: 50px; color: rgb(120, 197, 214); margin-bottom: 15px; width: 100%;">
            <table class="grid-item-card-body" style="box-sizing: border-box;">
                <tbody style="box-sizing: border-box;">
                    <tr style="box-sizing: border-box;">
                        <td class="grid-item-card-content" style="box-sizing: border-box; font-size: 13px; color: rgb(111, 119, 125); padding: 0 10px 20px 10px; width: 100%; line-height: 20px;" width="100%">
                            <h4 class="card-title" style="box-sizing: border-box;">Title here</h4>
                            <p class="card-text" style="box-sizing: border-box;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                            <div style="display: block; margin: auto;">
                                <a style="margin: auto; background-color: #dcdce0; border: 1px solid #47525d; padding: 5px 10px; font-size: 14px; text-decoration: none; border-radius: 8px; display: block; width: 80px; text-align: center;" data-gjs-type="link">Button</a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </td>
    </tr>
</tbody>
</table>
</td>
<td class="grid-item-cell2-r" style="box-sizing: border-box; vertical-align: top; padding-left: 10px; width: 50%;" width="50%" valign="top">
<table class="grid-item-card" style="box-sizing: border-box; width: 100%; padding: 5px 0 5px 0; margin-bottom: 10px;" width="100%">
<tbody style="box-sizing: border-box;">
    <tr style="box-sizing: border-box;">
        <td class="grid-item-card-cell" style="box-sizing: border-box; background-color: rgb(255, 255, 255); overflow: hidden; border-radius: 3px; text-align: center; padding: 0;" bgcolor="rgb(255, 255, 255)" align="center">
            <img src="https://via.placeholder.com/100" alt="Image" class="grid-item-image" style="box-sizing: border-box; line-height: 150px; font-size: 50px; color: rgb(120, 197, 214); margin-bottom: 15px; width: 100%;">
            <table class="grid-item-card-body" style="box-sizing: border-box;">
                <tbody style="box-sizing: border-box;">
                    <tr style="box-sizing: border-box;">
                        <td class="grid-item-card-content" style="box-sizing: border-box; font-size: 13px; color: rgb(111, 119, 125); padding: 0 10px 20px 10px; width: 100%; line-height: 20px;" width="100%">
                            <h4 class="card-title" style="box-sizing: border-box;">Title here</h4>
                            <p class="card-text" style="box-sizing: border-box;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                            <div style="display: block; margin: auto;">
                                <a style="margin: auto; background-color: #dcdce0; border: 1px solid #47525d; padding: 5px 10px; font-size: 14px; text-decoration: none; border-radius: 8px; display: block; width: 80px; text-align: center;" data-gjs-type="link">Button</a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </td>
    </tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
     </div>`,
    //   activeOnRender:true
  });

  editor.BlockManager.add("List-Item", {
    id: "list-items",
    label: "List Items",
    category: "Basic",
    attributes: { class: "fa fa-th-list" },
    content: `<div><table class="grid-item-row" style="box-sizing: border-box; margin-top: 0px; margin-right: auto; margin-bottom: 10px; margin-left: auto; padding-top: 5px; padding-right: 0px; padding-bottom: 5px; padding-left: 0px; width: 100%;" 
    width="100%">
    <tbody style="box-sizing: border-box;">
      <tr style="box-sizing: border-box;">
      </tr>
    </tbody>
  </table>
  <table class="list-item" style="box-sizing: border-box; height: auto; width: 100%; margin-top: 0px; margin-right: auto; margin-bottom: 10px; margin-left: auto; padding-top: 5px; padding-right: 5px; padding-bottom: 5px; padding-left: 5px;" width="100%">
    <tbody style="box-sizing: border-box;">
      <tr style="box-sizing: border-box;">
        <td class="list-item-cell" style="box-sizing: border-box; background-color: #ffffff !important; border-top-left-radius: 3px; border-top-right-radius: 3px; border-bottom-right-radius: 3px; border-bottom-left-radius: 3px; overflow-x: hidden; overflow-y: hidden; padding-top: 0px; padding-right: 0px; padding-bottom: 0px; padding-left: 0px;" bgcolor="rgb(255, 255, 255)">
          <table class="list-item-content" style="box-sizing: border-box; border-collapse: collapse; margin-top: 0px; margin-right: auto; margin-bottom: 0px; margin-left: auto; padding-top: 5px; padding-right: 5px; padding-bottom: 5px; padding-left: 5px; height: 150px; width: 100%;" width="100%" height="150">
            <tbody style="box-sizing: border-box;">
              <tr class="list-item-row" style="box-sizing: border-box;">
                <td class="list-cell-left" style="box-sizing: border-box; width: 30%; padding-top: 0px; padding-right: 0px; padding-bottom: 0px; padding-left: 0px;" width="30%">
                  <img src="https://via.placeholder.com/100" alt="Image" class="list-item-image" style="box-sizing: border-box; color: rgb(217, 131, 166); font-size: 45px; width: 100%;">
                </td>
                <td class="list-cell-right" style="box-sizing: border-box; width: 70%; padding-top: 10px; padding-right: 20px; padding-bottom: 0px; padding-left: 20px;" width="70%">
                  <h4 class="card-title" style="box-sizing: border-box;">Title here
                  </h4>
                  <p class="card-text" style="box-sizing: border-box;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  </p>
                                  <div style="display:block; margin:auto;"><a style="background-color: #dcdce0;border: 1px solid #47525d;padding: 5px 10px;font-size: 14px; text-decoration:none;border-radius: 8px; display: block;width:80px;text-align:center"data-gjs-type="link" >
Button
</a></div>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
  <table class="list-item" style="box-sizing: border-box; height: auto; width: 100%; margin-top: 0px; margin-right: auto; margin-bottom: 10px; margin-left: auto; padding-top: 5px; padding-right: 5px; padding-bottom: 5px; padding-left: 5px;" width="100%">
    <tbody style="box-sizing: border-box;">
      <tr style="box-sizing: border-box;">
        <td class="list-item-cell" style="box-sizing: border-box; background-color: #ffffff !important; border-top-left-radius: 3px; border-top-right-radius: 3px; border-bottom-right-radius: 3px; border-bottom-left-radius: 3px; overflow-x: hidden; overflow-y: hidden; padding-top: 0px; padding-right: 0px; padding-bottom: 0px; padding-left: 0px;" bgcolor="rgb(255, 255, 255)">
          <table class="list-item-content" style="box-sizing: border-box; border-collapse: collapse; margin-top: 0px; margin-right: auto; margin-bottom: 0px; margin-left: auto; padding-top: 5px; padding-right: 5px; padding-bottom: 5px; padding-left: 5px; height: 150px; width: 100%;" width="100%" height="150">
            <tbody style="box-sizing: border-box;">
              <tr class="list-item-row" style="box-sizing: border-box;">
                <td class="list-cell-left" style="box-sizing: border-box; width: 30%; padding-top: 0px; padding-right: 0px; padding-bottom: 0px; padding-left: 0px;" width="30%">
                  <img src="https://via.placeholder.com/100" alt="Image" class="list-item-image" style="box-sizing: border-box; color: rgb(217, 131, 166); font-size: 45px; width: 100%;">
                </td>
                <td class="list-cell-right" style="box-sizing: border-box; width: 70%; padding-top: 10px; padding-right: 20px; padding-bottom: 0px; padding-left: 20px;" width="70%">
                  <h4 class="card-title" style="box-sizing: border-box;">Title here
                  </h4>
                  <p class="card-text" style="box-sizing: border-box;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  </p>
                                 <div style="display:block; margin:auto;"><a style="background-color: #dcdce0;border: 1px solid #47525d;padding: 5px 10px;font-size: 14px; text-decoration:none;border-radius: 8px; display: block;width:80px;text-align:center" data-gjs-type="link">
Button
</a></div>
                </td>
              </tr> 
            </tbody> 
          </table>
        </td>
      </tr>
    </tbody> </table></div>`,
    activeOnRender: true,
  });

  editor.BlockManager.get("column1").set({
    content: `<div class=\"gjs-row gjs-grid r1\" data-gjs-type = \"row-1\" data-gjs-resizable='{\"tl\":0,\"tc\":0,\"tr\":0,\"cl\":0,\"cr\":0,\"bl\":0,\"br\":0,\"minDim\":1}' data-gjs-name=\"Row\">
        <div  class=\"cell gjs-clm col1\" data-gjs-type = \"grid-item\" data-gjs-draggable=\".gjs-row\" data-gjs-resizable='{\"tl\":0,\"tc\":0,\"tr\":0,\"cl\":0,\"cr\":1,\"bl\":0,\"br\":0,\"minDim\":1,\"bc\":0,\"currentUnit\":1,\"step\":0.2}' data-gjs-name=\"Cell\"></div>
      </div>
      <style>
      .gjs-clm{
        padding:5px 0;
      }
      .gjs-grid{
        min-height:auto;
        padding:10px 0;
      }
      </style>`,
  });

  editor.BlockManager.get("column2").set({
    content: `<div class=\"gjs-row gjs-grid r2\" data-gjs-type = \"grid-row\" data-gjs-resizable='{\"tl\":0,\"tc\":0,\"tr\":0,\"cl\":0,\"cr\":0,\"bl\":0,\"br\":0,\"minDim\":1}' data-gjs-name=\"Row\">
        <div  class=\"cell gjs-clm clm-2\"  data-gjs-type = \"grid-item\" data-gjs-draggable=\".gjs-row\" data-gjs-resizable='{\"tl\":0,\"tc\":0,\"tr\":0,\"cl\":0,\"cr\":1,\"bl\":0,\"br\":0,\"minDim\":1,\"bc\":0,\"currentUnit\":1,\"step\":0.2}' data-gjs-name=\"Cell\"></div>
        <div  class=\"cell gjs-clm clm-1\"  data-gjs-type = \"grid-item\" data-gjs-draggable=\".gjs-row\" data-gjs-resizable='{\"tl\":0,\"tc\":0,\"tr\":0,\"cl\":0,\"cr\":1,\"bl\":0,\"br\":0,\"minDim\":1,\"bc\":0,\"currentUnit\":1,\"step\":0.2}' data-gjs-name=\"Cell\"></div>
      </div>
      <style>
      .gjs-clm{
        padding:5px 0;
      }
      .gjs-grid{
        min-height:auto;
        padding:10px 0;
      }
      </style>`,
  });

  editor.BlockManager.get("column3").set({
    content: `<div class=\"gjs-row gjs-grid r3\" data-gjs-type = \"grid-row\" data-gjs-resizable='{\"tl\":0,\"tc\":0,\"tr\":0,\"cl\":0,\"cr\":0,\"bl\":0,\"br\":0,\"minDim\":1}' data-gjs-name=\"Row\">
        <div  class=\"cell gjs-clm cl-3\" data-gjs-type = \"grid-item\" data-gjs-draggable=\".gjs-row\" data-gjs-resizable='{\"tl\":0,\"tc\":0,\"tr\":0,\"cl\":0,\"cr\":1,\"bl\":0,\"br\":0,\"minDim\":1,\"bc\":0,\"currentUnit\":1,\"step\":0.2}' data-gjs-name=\"Cell\"></div>
        <div  class=\"cell gjs-clm cl-2\" data-gjs-type = \"grid-item\" data-gjs-draggable=\".gjs-row\" data-gjs-resizable='{\"tl\":0,\"tc\":0,\"tr\":0,\"cl\":0,\"cr\":1,\"bl\":0,\"br\":0,\"minDim\":1,\"bc\":0,\"currentUnit\":1,\"step\":0.2}' data-gjs-name=\"Cell\"></div>
        <div  class=\"cell gjs-clm cl1\" data-gjs-type = \"grid-item\" data-gjs-draggable=\".gjs-row\" data-gjs-resizable='{\"tl\":0,\"tc\":0,\"tr\":0,\"cl\":0,\"cr\":1,\"bl\":0,\"br\":0,\"minDim\":1,\"bc\":0,\"currentUnit\":1,\"step\":0.2}' data-gjs-name=\"Cell\"></div>
      </div>
      <style>
      .gjs-clm{
        padding:5px 0;
      }
      .gjs-grid{
        min-height:auto;
        padding:10px 0;
      }
      </style>`,
  });
}
